import React, { useState, useEffect, useContext, useRef } from 'react';
import AccessManagerContext from 'context/AccessManagerContext';
import strings from 'localizations/formScreen';
import homeScreenStrings from 'localizations/homeScreen';
import formBuilderScreenStrings from 'localizations/formBuilderScreen';
import IAccessManager from 'api/IAccessManager';
import SFAPI from 'api/sfapi';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import CQFormBuilderSFSection from '../CQFormBuilderSFSection';
import CQToast from '../../CQToast/CQToast';
import CQFormBuilderSignatureSection from 'components/editor/CQFormBuilderSignatureSection';
import CQFormBuilderSelectObjectModal from 'components/editor/CQFormBuilderSelectObjectModal';
import CQFormBuilderLogo from 'components/editor/CQFormBuilderLogo';
import CQFormBuilderFileAttachment from 'components/editor/CQFormBuilderFileAttachment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { EditorRenderers, Cells } from 'renderer';
import { createAjv } from '@jsonforms/core';

import Spinner from '@salesforce/design-system-react/components/spinner';
import Modal from '@salesforce/design-system-react/components/modal';
import CQGenericFieldModal from 'components/CQGenericFieldModal';
import { JsonForms } from '@jsonforms/react';
import {CQFormLayout} from '../CQFormLayout';
import { CQFSchemaBuilder } from '../schemaBuilder';
import { CQFormBuilderConstants } from '../formbuilder-constants';
import { CQFormJSONProcessor } from '../CQFormJSONProcessor';
import CQFormBuilderGrid from '../CQFormBuilderGrid';
import CQSectionHeader from 'components/CQSectionHeader';
import { fillInValues, getRelationalAPIName, transformDataToClassicFormat } from 'services/data-mapping.service';
import { isArray } from 'lodash';


/**
 * This method returns formatted structure for schema for table
 * @param columns 
 * @returns 
 */
export const getFormattedProperties = (columns: any) => {
    let updatedProperties:any = {};
    columns.map((column) => {
        updatedProperties[column.name] = {id: column.id, type: 'string', title: column.title, regexRuleName: column.regexRuleName, pattern: column.pattern, errorMessage: column.errorMessage}
    })
    return updatedProperties;
}
/*
 * Form Builder component used for Editing Form in salesforce
 */
function CQFormBuilderSF(props) {

    const accessManager: IAccessManager = useContext(AccessManagerContext);
    const sfAPI = new SFAPI().setAccessManager(accessManager);

    const [isLoaded, setIsLoaded] = useState(false);
    const [reload, setReload] = useState(false);
    const [isObjectSelected, setIsObjectSelected] = useState(false);
    const [isdropAreaUpdated, setIsdropAreaUpdated] = useState(false);
    const [errors, setErrors] = useState('');
    const [isFieldsLoaded, setIsFieldsLoaded] = useState(false);
    const [isFormSave, setIsFormSave] = useState(false);
    const [toggleForSignature, setToggleForSignature] = useState(false);
    const [toggleForSelectObject, setToggleForSelectObject] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [errorToastMessage, setErrorToastMessage] = useState('');
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [successToastMessage, setSuccessToastMessage] = useState('');
    const [toggleForGenericModal, setToggleForGenericModal] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);
    const [isTextfield, setIsTextfield] = useState(false);
    const [isDateField, setIsDateField] = useState(false);
    const [isBlank, setIsBlank] = useState(false);

    // this is used to store dragged field api names
    const [draggedItems, setDraggedItems] = useState<string[]>([]);
    // this is used to store fields which are made as required at field's schema in object level
    const [requiredFieldsAtSchemaLevel, setRequiredFieldsAtSchemaLevel] =  useState([]);
    const [blacklistFieldToAvoidRequiredAtSchema, setBlacklistFieldToAvoidRequiredAtSchema] =  useState<any[]>([]);
    const [requiredContextFieldsAtSchemaLevel, setRequiredContextFieldsAtSchemaLevel] =  useState([]);
    const [readOnlyFieldsList, setReadOnlyFieldsList] =  useState<string[]>([]);
    const [rightPanelItemsList, setRightPanelItemsList] = useState([]);
    const [lookupCacheData, setLookupCacheData] = useState([]);
    const [startingDataCache, setStartingDataCache] = useState<any[]>([]);
    const [selectedDocs, setSelectedDocs] = useState<any[]>([]);
    const [docIdAndLookupCacheMap, setDocIdAndLookupCacheMap] = useState<any>();
    const [docIdAndStartingDataCacheMap, setDocIdAndStartingDataCacheMap] = useState<any>();
    const [signature, setSignature] = useState('');
    const [schemaSignatures, setSchemaSignatures] = useState<Array<object>>([]);
    const [selectedObjectFieldsList, setSelectedObjectFieldsList] = useState([]);
    const [contextObjectFieldsList, setContextObjectFieldsList] = useState([]);
    const [selectedObjectFieldsAPIList, setSelectedObjectFieldsAPIList] = useState([]);
    const [contextObjectFieldsAPIList, setContextObjectFieldsAPIList] = useState<any>([]);
    const [genericFieldTitle, setGenericFieldTitle] = useState("");
    const [fieldLabel, setFieldLabel] = useState("");
    const [defaultValue, setDefaultValue] = useState("");
    const [fieldAPIName, setFieldAPIName] = useState("");
    const [dropdownValues, setDropdownValues] = useState<Array<object>>([]);
    const [dropdownLabel, setDropdownLabel] = useState("");
    const [edittingFieldId, setEdittingFieldId] = useState("");
    const [currentUiSchemaId, setCurrentUiSchemaId] = useState<number>(0);
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [isLogoUpdated, setIsLogoUpdated] = useState(true);
    const [imgSrc, setImgSrc] = useState('');
    const [selectedObjectApiName, setSelectedObjectApiName] = useState('');
    const [contextObjectApiName, setContextObjectApiName] = useState('');
    const [contextObjectRelationshipName, setContextObjectRelationshipName] = useState('');
    const [isChildDropdown, setIsChildDropdown] = useState(false);
    const [isDragged, setIsDragged] = useState(false);
    const [isFileAttachmentAdded, setIsFileAttachmentAdded] = useState(false);
    const [numberOfGrid, setNumberOfGrid] = useState<any>([]);
    const [selectObjectState, setSelectObjectState] = useState<any>(
        {
            inputValue: '',
            selection : []
        }
    )
    const[contextObjectList, setContextObjectList] = useState<Array<object>>([]);
    const [contextObjectState, setContextObjectState] = useState<any>(
        {
            inputValue: '',
            selection : []
        }
    )
    
    const [formStyleObj, setFormStyleObj] = useState<any>(
        {
            "header": {
                "logo": "",
                "backgroundColor": "<HEX Color Value>"
            }
        } 
    );
    
    /**
     * Section Handling 
     */
    const [sectionsInfo, setSectionsInfo] : Array<any> = useState<Array<any>>([[]]);
    const SQX_Safety_Inspection = "SQX_Safety_Inspection__c";

    const handleAddSection =  () => {
        if(selectedObjectApiName.includes(SQX_Safety_Inspection)){
            uiSchema.isDocumentType = true;
        }
        uiSchema.addSectionInUISchema();
        setSectionsInfo(prevState  => [...prevState , []]);
        setIsSectionToggle(prevState  => [...prevState , false]);
    }
    
    const [signatureUISchema, setSignatureUISchema] = useState<any>(
        {
            "type": "Group",
            "label": "Signatures",
            "elements": [
                {
                    "type": "signatures",
                    "scope": "#/properties/cqext__SQX_Safety_Inspection__c/properties/Signatures"
                }
            ]
        }
    );

    const [sObjectSchema, setsObjectSchema] = useState<any>(
        {
            "objectType": "",
            "fields": [],
            "child": {},
            "context":{} // added a context property to hold information for selected context object
        }
    );

    // dynamically get starting layout based on form type for now we can limit it to forms type
    const [uiSchema, setUISchema] = useState<CQFormLayout>(new CQFormLayout({
        "type": "VerticalLayout",
        'sectionId': '1',
        "elements": [
            {
                "label": "Section Header Title Area",
                "type": "Group",
                "elements": [
                    {
                        "type": "HorizontalLayout",
                        "elements": [
                        ]
                    }
                ]
            }
        ]
    }));
    let isColorFieldAvailable = useRef(false);

    // Start of the states and ref for table
    let initialRowData: any = useRef<any>([
        [{}, {}, {}],
        [{}, {}, {}],
        [{}, {}, {}],
    ]);

    let initialColumnData: any = useRef([
        { id:"C1", name: "C1", title: "Column 1", regexRuleName: "", pattern: "", errorMessage: ""},
        { id:"C2", name: "C2", title: "Column 2", regexRuleName: "", pattern: "", errorMessage: ""},
        { id:"C3", name: "C3", title: "Column 3", regexRuleName: "", pattern: "", errorMessage: ""},
    ]);
    
    const [schemaTotalTableRows, setSchemaTotalTableRows] = useState({});
    const [schemaTableColumns, setSchemaTableColumns] = useState<any>({});
    const [schemaTableHeader, setSchemaTableHeader] = useState<any>({});
    const [isSafetyInspection, setIsSafetyInspection] = useState(false);
    const [schemaTableColumnWidth, setSchemaTableColumnWidth] = useState({});
    const [disableTableDrag, setDisableTableDrag] = useState(false);
    const [generalData, setGeneralData]= useState<object>({});
    const [validations, setValidations] = useState({regexRuleName : "", pattern: "",errorMessage:""});
    const [isPrimaryFieldModal, setIsPrimaryFieldModal] = useState(false);
    const [primaryFieldApiName, setPrimaryFIeldApiName] = useState("");
    // End of the states and ref for table

    const GENERAL : string = "General";
    const SAFETY_INSPECTION_LABEL : string = "Safety Inspection";
    const SUPPLIER_INTERACTION_STEP_OBJECT : string = "compliancequest__SQX_Supplier_Interaction_Step2__c";
    const DATA_TYPE_REFERENCE : string = "reference";
    const RESULT_TYPE_VALUE_OBJECT = "compliancequest__SQX_Result_Type_Value__c";
    const COLOR_FIELD = "compliancequest__Color__c";

    let lookupCacheString = `{"objectType":"compliancequest__SQX_Result_Type_Value__c","fields":["Name","cqext__Response_Label__c","compliancequest__SQX_Result_Type__c"${isColorFieldAvailable.current ? `,"${COLOR_FIELD}"` : ''}],"filter":null,"filterByParent":"cqext__SQX_Question__c","parentField":"cqext__SQX_Response_Type__c","connectionField":"compliancequest__SQX_Result_Type__c","orderbyFields":["CreatedDate"],"orderByDirection":"ASC"}`;

    let savedFormBuilderData : any;
    let cqFormJSONProcessor = new CQFormJSONProcessor(accessManager);

    /**
     * Get form builder JSON and show the elements on page load.
     */
    const getFormJSON = async () => {
        try {
            await cqFormJSONProcessor.getFormJSON(props.recordId, uiSchema, lookupCacheString).then( async (res)=>{
                setCurrentFormData(cqFormJSONProcessor.deserializedFormJSON);
                setSelectedDocs(cqFormJSONProcessor.selectedDocsIds);
                setDraggedItems(cqFormJSONProcessor.draggedItems);
                if(cqFormJSONProcessor?.formBuilderData){
                    setGeneralData(cqFormJSONProcessor.formBuilderData);
                }
                 // set context object value for selected context object
                if(cqFormJSONProcessor.deserializedFormJSON.hasOwnProperty('contextObjectRelationshipName') && cqFormJSONProcessor.deserializedFormJSON.hasOwnProperty('contextObject')){
                    setContextObjectRelationshipName(cqFormJSONProcessor.deserializedFormJSON.contextObjectRelationshipName);
                    setContextObjectApiName(cqFormJSONProcessor.deserializedFormJSON.contextObject);
                }
                if(cqFormJSONProcessor.deserializedFormJSON.hasOwnProperty('cqFormType')){
                    const label = await cqFormJSONProcessor.getLabelByAPIName(cqFormJSONProcessor.deserializedFormJSON.cqFormType);
                    setSelectObjectState({selection:[{label:label, type:cqFormJSONProcessor.deserializedFormJSON.cqFormType}]})
                }
                if(cqFormJSONProcessor.deserializedFormJSON.hasOwnProperty('contextObject')){
                    const label =await cqFormJSONProcessor.getLabelByAPIName(cqFormJSONProcessor.deserializedFormJSON.contextObject);
                    setContextObjectState({selection:[{label:label, type:cqFormJSONProcessor.deserializedFormJSON.cqFormType}]})
                }
                setSelectedObjectApiName(cqFormJSONProcessor.deserializedFormJSON.cqFormType);
                if(cqFormJSONProcessor.deserializedFormJSON.version < '2.0.0'){
                    cqFormJSONProcessor.deserializedFormJSON['ui'] = cqFormJSONProcessor.deserializedFormJSON.ui? mergeUISchema(cqFormJSONProcessor.deserializedFormJSON.ui): {};
                    setUISchema(cqFormJSONProcessor.deserializedFormJSON.ui);
                    cqFormJSONProcessor.deserializedFormJSON.ui = uiSchema;
                    cqFormJSONProcessor.deserializedFormJSON.ui = cqFormJSONProcessor.uischema.uischema;
                    cqFormJSONProcessor.deserializedFormJSON.ui.elements = cqFormJSONProcessor.deserializedFormJSON.ui.elements[0].elements.filter((item) => item.label !== 'Files')
                    cqFormJSONProcessor.deserializedFormJSON['sectionsInfo'] = sectionsInfo;
                    cqFormJSONProcessor.uischema.uischema = cqFormJSONProcessor.deserializedFormJSON.ui;
                    cqFormJSONProcessor.uischema.isDocumentType ? cqFormJSONProcessor.uischema.uischema.elements[0].elements[0].label = '' : cqFormJSONProcessor.uischema.uischema.elements[0].label = '';
                }else{
                    let isTableAvailable : boolean = false;
                    if (cqFormJSONProcessor.deserializedFormJSON.ui.elements.length > 0) {
                        let uiElements = cqFormJSONProcessor.deserializedFormJSON.ui.elements;
                        cqFormJSONProcessor.deserializedFormJSON.ui.elements.map((uiElement:any) => {
                            uiElement.elements.map((item:any, index) => {
                                if(item.type === 'table') {
                                    let updatedProperties:any = {[item.label]: []};
                                    let schemaTableTitle = cqFormJSONProcessor.deserializedFormJSON.schema.definitions[item.label].properties[item.label].title;
                                    numberOfGrid.push({numberOfGrid: numberOfGrid.length+1, tableId: item.label});
                                    if(item.options.hasOwnProperty('columnWidth')) {
                                        Object.assign(schemaTableColumnWidth, {[item.label]: {}})
                                        Object.keys(item.options.columnWidth).map((innerKey) => {
                                            Object.assign(schemaTableColumnWidth[item.label], {[innerKey]: item.options.columnWidth[innerKey]})
                                        })
                                    }
                                    Object.assign(schemaTableHeader,{[item.label]: {'title': schemaTableTitle}});
                                    Object.assign(schemaTotalTableRows, {[item.label]: {totalRows: item.options.rowsCount}});
                                    cqFormJSONProcessor.deserializedFormJSON.schema.definitions[item.label].properties[item.label]['format'] = 'table';
                                    Object.keys(cqFormJSONProcessor.deserializedFormJSON.schema.definitions[item.label].properties[item.label].properties).map((key) => {
                                        let props = cqFormJSONProcessor.deserializedFormJSON.schema.definitions[item.label].properties[item.label].properties;
                                        updatedProperties[item.label].push({'id': props[key].id ,'name':key, 'title': props[key].title, 'regexRuleName': props[key].regexRuleName, 'pattern': props[key].pattern, 'errorMessage': props[key].errorMessage});
                                        Object.assign(schemaTableColumns, updatedProperties);
                                    })
                                    setSchemaTableColumnWidth(schemaTableColumnWidth);
                                    setSchemaTableColumns(schemaTableColumns);
                                    setSchemaTableHeader(schemaTableHeader);
                                    isTableAvailable = true;
                                }
                            })
                        })
                        if(isTableAvailable){
                            for(let index = 0; index < uiElements.length ; index ++){
                                uiElements[index] = removeTableUISchema(uiElements[index]);
                            }
                            cqFormJSONProcessor.uischema.uischema.elements = uiElements;
                        }
                    }
                    cqFormJSONProcessor.uischema.uischema = cqFormJSONProcessor.deserializedFormJSON.ui
                }
                //remove signatures UI
                cqFormJSONProcessor.deserializedFormJSON.ui.elements = cqFormJSONProcessor.deserializedFormJSON.ui.elements.filter((item) => {
                    if(item.type !== 'Group') return item
                    if(!item.hasOwnProperty('label')) return item;
                    if(item.label !== 'Signatures') return item;
                })

                //set logo in saved Form JSON
                if(cqFormJSONProcessor.deserializedFormJSON.styles.header.logo !== ''){
                    setIsLogoUpdated(true);
                    setFormStyleObj(cqFormJSONProcessor.deserializedFormJSON.styles);
                    setImgSrc(cqFormJSONProcessor.deserializedFormJSON.styles.header.logo);
                }
                // set signatures in saved Form JSON
                if(cqFormJSONProcessor.deserializedFormJSON.signatures.length > 0){
                    setSchemaSignatures(cqFormJSONProcessor.deserializedFormJSON.signatures);
                }
                if(cqFormJSONProcessor.deserializedFormJSON.hasOwnProperty('objectSelection')) {
                    setSelectObjectState(cqFormJSONProcessor.deserializedFormJSON.objectSelection)
                }
                setIsFileAttachmentAdded(cqFormJSONProcessor.isFileAttachmentAdded);
                if(cqFormJSONProcessor.deserializedFormJSON.ui.hasOwnProperty('elements')){
                    if(cqFormJSONProcessor.deserializedFormJSON.version > '1.2.0'){
                        cqFormJSONProcessor.deserializedFormJSON.ui.elements = cqFormJSONProcessor.deserializedFormJSON.ui.elements.filter((item) => item.type === 'VerticalLayout' && item.label !== 'Files')
                    }
                }
                setData(cqFormJSONProcessor.data);
                setQueries(cqFormJSONProcessor.queries)
                setLookupCacheData(cqFormJSONProcessor.lookupCacheData);
                if(cqFormJSONProcessor.startingDataCache) setStartingDataCache(cqFormJSONProcessor.startingDataCache);
                setFrozenSchema(cqFormJSONProcessor.deserializedFormJSON.schema);
                setUISchema(cqFormJSONProcessor.uischema.refresh());
                setReadOnlyFieldsList(cqFormJSONProcessor.readOnlyFieldsList);
                setSectionsInfo(cqFormJSONProcessor.deserializedFormJSON.sectionsInfo);          
                if(cqFormJSONProcessor.deserializedFormJSON.sectionsInfo.length>1){
                    for (let i = 0; i < cqFormJSONProcessor.deserializedFormJSON.sectionsInfo.length-1; i++) {
                            setIsSectionToggle(prevState => [...prevState, false]);//Open all the the section if form is saved
                    }
                }
            });
        }catch (e) {
            if (e.response && e.response.data) {
                setErrors(homeScreenStrings.serverError + JSON.stringify(e.response.data));
            } else {
                if (e.message === 'Network Error') {
                    setErrors(strings.networkError);
                } else {
                    setErrors(JSON.stringify(e.message));
                }
            }
        }
    }

    const removeTableUISchema = (element) => {
        element.elements = element.elements.filter(item => item.type != 'table')
        return element;
    } 

    /**
     * Method used to get fields metadata of selected CQ Form Type value (i.e., selected object)
     */
    const getMetadata = async (selectedObject: string,  contextObject?:string) => {
        try {
            const contextLabel = contextObject ? contextObjectState.selection.length ? contextObjectState.selection[0]['label'] :(currentFormData && currentFormData['cqFormObjectSchema']['contextLabel'] ) : "";
            const objectLabel : string = selectObjectState.selection.length ? selectObjectState.selection[0]['label'] : (currentFormData && currentFormData['cqFormObjectSchema']['primaryLabel']);
            if(selectedObject.includes(SQX_Safety_Inspection)){
                setUISchema(CQFormBuilderConstants.safetyInspectionUiSchema);
                setsObjectSchema(CQFormBuilderConstants.safetyInspectionSchema);
                setIsSafetyInspection(true);
                handleColorFieldOnDocumentSelection();
            }

            let customDataSet: any = [
                {
                    'type': 'GeneralField',
                    'name': formBuilderScreenStrings.generalSection,
                    'droptype': 'FIELD',
                    'items': [
                        {
                            'id': 'G0',
                            'label': 'Dropdown',
                            'value': 'Dropdown'
                        },
                        {
                            'id': 'G1',
                            'label': 'Text Field',
                            'value': 'Text Field'
                        },
                        {
                            'id': 'G2',
                            'label': 'Date Field',
                            'value': 'Date Field'
                        },
                        {
                            'id': 'G3',
                            'label': 'Yes/No Field',
                            'value': 'Yes/No Field'
                        },
                        {
                            'id': 'G4',
                            'label': 'Table',
                            'value': 'Table'
                        },
                        {
                            'id': 'G5',
                            'label': 'Blank Space',
                            'value': 'Blank Space'
                        }
                    ]
                },
                {
                    'type': 'ObjectField',
                    'name': formBuilderScreenStrings.fieldsSection+' ('+objectLabel+')',
                    'droptype': 'FIELD',
                    "items": [

                    ]
                },
                {
                    'type': 'ContextObjectField',
                    'name': formBuilderScreenStrings.relatedFieldsSection + ' ('+contextLabel+')',
                    'droptype': 'FIELD',
                    "items": [
                    ]
                }
            ];

            if(selectedObject.includes(SQX_Safety_Inspection)){
                customDataSet.push({
                    'type': 'QueryField',
                    'name': formBuilderScreenStrings.documentsSection,
                    'droptype': 'DOCUMENTS',
                    "items": [
                    ]
                });
            }
            
            // Method used to get fields metadata of selected CQ Form Type value (i.e., selected object)
            const getFieldsMetadata = async () => {
                try {
                    // declared a variable to store fields of both main and context object
                    let allObjectFields:any = [];
                    if(selectedObject.length && selectedObject !== GENERAL){
                        signatureUISchema["elements"][0]["scope"] = `#/properties/${selectedObject}/properties/Signatures`;
                        let response = await sfAPI.getSeletectedObjectMetadata(selectedObject);
                        // check if context object is not selected or is undefined
                        if(contextObject !== '' && contextObject !== undefined) {
                            let contextResponse = await sfAPI.getSeletectedObjectMetadata(contextObject);
                            if(contextResponse) {
                                // updated structure of context object to differentiate from main object fields
                                contextResponse.fields.map((field) => {
                                    field['isReferenceField'] = true;
                                    field['contextObject'] = contextLabel;
                                })
                                allObjectFields = [...response.fields, ...contextResponse.fields];
                            }
                        } else {
                             allObjectFields = [...response.fields];
                        }
                        if(allObjectFields){
                            let fieldsListOfSelectedObject : any = selectedObjectFieldsList;
                            let relatedFieldsListOfobject: any = contextObjectFieldsList;
                            let selectedObjectFieldsApiNameList : any = [];
                            let blacklistFieldToAvoidRequiredAtSchema : any = [];
                            for(let i=0; i<allObjectFields.length ; i++){
                                let fieldItem : any = allObjectFields[i];
                                if(fieldItem.name !== 'Id' && fieldItem.name !== 'IsDeleted' && fieldItem.name !== 'SystemModstamp'){

                                    // Avoiding standard fields [other than Name(data type as auto number) and RecordTypeId] and boolean fields to show as required on CQ Form
                                    if (!fieldItem.isReferenceField && fieldItem.name !== 'RecordTypeId') {
                                        // Field is not coming from the selected context object and field is not RecordTypeId
                                        if (fieldItem.name === 'Name' && fieldItem.autoNumber) {
                                            // Field is 'Name' and has autoNumber property as true
                                            blacklistFieldToAvoidRequiredAtSchema.push(fieldItem.name);
                                        }else if (!fieldItem.custom || fieldItem.type === 'boolean') {
                                            // Field is 'standard' or boolean field
                                            blacklistFieldToAvoidRequiredAtSchema.push(fieldItem.name);
                                        }
                                    }
                                      

                                    let obj = {};
                                    obj['id'] = fieldItem['contextObject'] ? contextObjectRelationshipName+'.'+fieldItem.name : fieldItem.name;
                                    obj['label'] = fieldItem.label;
                                    obj['value'] = fieldItem.name;
                                    obj['isDragged'] = false;
                                    obj['type'] = 'FIELD';
                                    obj['isReferenceField'] = fieldItem['isReferenceField'] ? fieldItem['isReferenceField']: false
                                    obj['contextObject'] = fieldItem['contextObject'] ? fieldItem['contextObject']: null
                                    if(draggedItems.length && draggedItems.indexOf(obj['id']) > -1){
                                        obj['isDragged'] = true;
                                    }
                                    
                                    let uiDefinition = { "type" : "", "scope" : "", "options" : {"edited": false}}; 
                                    let dataType = fieldItem.type;
                                    //checks data type and set uischema
                                    if(dataType === DATA_TYPE_REFERENCE){
                                        uiDefinition["type"] = "lookup";
                                        uiDefinition["lookupTo"] = fieldItem.referenceTo[0];
                                    }else if(dataType !== "picklist"){
                                        uiDefinition["type"] = "Control";
                                    }else{
                                        uiDefinition["type"] = "select";
                                        uiDefinition["options"]["showLabel"] = true;
                                    }
                                    obj['fieldUISchema'] = uiDefinition;
                                    // check if incoming field is context field or main object field
                                    if (fieldItem.isReferenceField) {
                                        if(!contextObjectFieldsAPIList.includes(fieldItem.name.toLowerCase())) {
                                            relatedFieldsListOfobject.push(obj);
                                            contextObjectFieldsAPIList.push(fieldItem.name.toLowerCase());
                                        }                                    
                                    }else{
                                        if(!selectedObjectFieldsApiNameList.includes(fieldItem.name.toLowerCase())) {
                                            fieldsListOfSelectedObject.push(obj);
                                            selectedObjectFieldsApiNameList.push(fieldItem.name.toLowerCase());
                                        }
                                    }
                                } 
                            }
                            setBlacklistFieldToAvoidRequiredAtSchema(blacklistFieldToAvoidRequiredAtSchema);
                            setContextObjectFieldsList(relatedFieldsListOfobject);
                            setContextObjectFieldsAPIList(contextObjectFieldsAPIList);
                            setSelectedObjectFieldsList(fieldsListOfSelectedObject);
                            setSelectedObjectFieldsAPIList(selectedObjectFieldsApiNameList);
                            customDataSet[1]['items'] = fieldsListOfSelectedObject;
                            // added a new dataset for context object fields
                            customDataSet[2]['items'] = relatedFieldsListOfobject;
                        }
                    }else{
                        signatureUISchema["elements"][0]["scope"] = `#/properties/${GENERAL}/properties/Signatures`;
                        let generalSchema : any = {
                            "properties":{
                                "General" : {
                                    "properties" : {}
                                }
                            },
                            "requiresSignature": false,
                            "type":"object"
                        }
                        Object.keys(generalSchema.properties).map((key,index) => {
                            generalSchema.properties[Object.keys(generalSchema.properties)[index]].required = [];
                            if(key === GENERAL && savedFormBuilderData){
                                generalSchema.properties[Object.keys(generalSchema.properties)[index]] = savedFormBuilderData.schema.properties[GENERAL];
                            }
                        })
                        setFrozenSchema(generalSchema);
                        setSchema(JSON.parse(JSON.stringify(frozenSchema)));
                    }
                    setSignatureUISchema(signatureUISchema);
                }catch(e) {
                    if(e.response && e.response.data){
                        setErrors(homeScreenStrings.serverError + JSON.stringify(e.response.data));
                    }else{
                        if(e.message === 'Network Error'){
                            setErrors(strings.networkError);
                        }else{
                            setErrors(JSON.stringify(e.message));
                            setErrorToastMessage(JSON.stringify(e.message));
                            setShowToast(true);
                        }
                    }
                }
            }
            const getDocuments = async(safetyCriteriaType : string) => {
                try {
                    let response = await sfAPI.getDocumentsOfSeletectedObjectMetadata(safetyCriteriaType);
                    if(response){
                        let documentList : any = [];
                        let itemsList : any = [...selectedObjectFieldsList];
                        for(let i=0; i<response.records.length ; i++){
                            let docItem : any = response.records[i];
                            let obj = {};
                            obj['id'] = docItem['Name'];
                            obj['label'] = docItem['compliancequest__Document_Number__c'] + ': '+ docItem['compliancequest__Title__c'];
                            obj['value'] = docItem.Id;
                            obj['isDragged'] = false;
                            obj['type'] = 'DOCUMENT';
                            if(selectedDocs.indexOf(docItem.Id) > -1){
                                obj['isDragged'] = true;
                            }
                            documentList.push(obj);
                            itemsList.push(obj);
                        }
                        customDataSet[3]['items'] = documentList;
                        setSelectedObjectFieldsList(itemsList);
                    }
                }catch(e) {
                    if(e.response && e.response.data){
                        setErrors(homeScreenStrings.serverError + JSON.stringify(e.response.data));
                    }else{
                        if(e.message === 'Network Error'){
                            setErrors(strings.networkError);
                        }else{
                            setErrors(JSON.stringify(e.message));
                        }
                    }
                }
            }

            getFieldsMetadata().then(async () => { 
                if(selectedObject.length && selectedObject.includes(SQX_Safety_Inspection)){
                    //call only if it is safety inspection
                    await getDocuments(SAFETY_INSPECTION_LABEL);
                }else{
                    setIsdropAreaUpdated(true);
                }
                setRightPanelItemsList(JSON.parse(JSON.stringify(customDataSet)));
                setIsFieldsLoaded(true);
                setIsLoaded(true);
                setReload(true);    
            });
        } catch (e) {
            if (e.response && e.response.data) {
                setErrors(homeScreenStrings.serverError + JSON.stringify(e.response.data));
            } else {
                if (e.message === 'Network Error') {
                    setErrors(strings.networkError);
                } else {
                    setErrors(JSON.stringify(e.message));
                }
            }
        }
    }

    /**
     * This method used to handle logo changes and get the image data string
     * @param evt 
     */
    const onLogoChangeEvent = (evt : any) => {
        let styleObj : any = formStyleObj;
        styleObj['header']['logo'] = evt.detail.imgSrcString;
        setFormStyleObj(styleObj);
        setImgSrc(evt.detail.imgSrcString);
    }

    useEffect(() => {
        //Identifier
        getFormJSON().then(() =>{
            if(!cqFormJSONProcessor.formSavedJSON){
                setIsObjectSelected(false);
                setToggleForSelectObject(true);
            }
        });

        document.addEventListener("cqformlogosave", onLogoChangeEvent);

        return () => {
            document.removeEventListener("cqformlogosave", onLogoChangeEvent);
        }
    }, []);


    useEffect(() => {
        document.addEventListener('cqTableDataEvent', onGeneralTableDataChange);

        return () => {
            document.removeEventListener('cqTableDataEvent', onGeneralTableDataChange);
        }   
    })


    const onGeneralTableDataChange = (e : any) => {
        if(e.detail.oldKey !== e.detail.newKey) {
            delete generalData[e.detail.oldKey];
        }
        setGeneralData(prevItem => {
            return {...prevItem,...e.detail.data};
        });
    }


    // style used for Form Builder container
    const customContainerStyle = {
        padding: "0.8rem 1rem",
        height: "94vh"
    }

    // style used for left panel in Form Builder container
    const builderDragDropAreaStyle = {
        background: "rgb(229 226 226 / 68%)",
        border: '1px solid #c7c4c4'
    }

    const builderFieldAreaStyle = {
        border: '1px solid #c7c4c4'
    }

    // style used for section of modal appears when adding signature
    const modalSectionStyle = {
        padding: '1rem'
    }
    
    // style used for modal text input
    const modalInputStyle = {
        width: '100%'
    }
    
    // style used for modal footer section 
    const modalFooterStyle = {
        display: 'flex',
        justifyContent: 'flex-end'
    }

    /**
     * This method saves the signature added by user
     */
    const saveSignature = () => {
        let signatureObject = {"Purpose": signature}; // creating a object holding user added signature
        
        // schemaSignatures contains array of object that holds object with user added signature
        schemaSignatures.push(signatureObject);

        setSignature(''); 
        setToggleForSignature(false); // close Modal
      }

    
    /**
     * This method takes the signature to be removed when user deletes it
     * @param signature 
     */
    const removeSignature = (signature) => {
        let indexOfSignature = schemaSignatures.indexOf(signature); // store index of signature to be removed from signatures array
        if (indexOfSignature !== -1) { // check if signature is in the array
            schemaSignatures.splice(indexOfSignature, 1); //remove signature from array
            setSchemaSignatures([...schemaSignatures]);
            if(!schemaSignatures.length && (uiSchema && uiSchema.uischema && uiSchema.uischema.elements)){
                uiSchema.uischema.elements.forEach((item, index) => {
                    if(item.label === "Signatures"){
                        delete uiSchema.uischema.elements[index]
                    }
                });
                setUISchema(uiSchema);
            }
            
        }
    }

    /**
     * This method updates the label which user adds on editing field
     * @param evt 
     */
    const handleGenericFieldChange = (evt:any) => {
        setFieldLabel(evt.target.value);
    }

    /**
     * This method updates the api name on editing the label
     * @param evt 
     */
    const handleApiNameChange = (evt:any) => {
        let apinameValue = '';
        if(evt.target.value && !fieldAPIName){
            apinameValue = 'CQ_Form_'+evt.target.value.replace(/[\s\W]+/g, '_');
        }
        setFieldAPIName(apinameValue);
    }

    /**
     * This method updates the input of dropdown values
     * @param evt 
     */
    const handleDropDownChange = (evt: any) => {
        setDropdownLabel(evt.target.value);
    }

    /**
     * This method adds dropdown value that users add on editing field
     */
    const addDropDownValues =() => {
        let dropDownValue = {"const": dropdownLabel, "title": dropdownLabel}; // creating an object that schema supports for dropdown

        if (!(dropdownValues.includes(dropDownValue)) && dropdownLabel !== "") { // check if dropdown input is empty or not
            dropdownValues.push(dropDownValue)
            setDropdownLabel("");
        }
    }

    /**
     * This method removes dropdown value when user clicks delete icon
     * @param index
     */
    const removeDropdownValue = (index: number) => {
        dropdownValues.splice(index, 1);
        setDropdownValues([...dropdownValues]);
    }

    const handleDefaultValue = (evt : any) => {
        setDefaultValue(evt.target.value);
    }

    /**
     * This method saves field values and updates schema accordingly
     * @param fieldApiName
     */
    const saveModal = (fieldApiName: string, regexName : string, pattern : string, currentDefaultData: string, errorMessage : string) => {
        if(schema.properties?.General?.properties && Object.keys(schema.properties.General.properties).includes(fieldApiName) && uiSchema.fieldApiNameAndControlIdMap.get(fieldApiName) !== currentUiSchemaId){
            //show error message
            setErrorToastMessage(formBuilderScreenStrings.apiNameDuplicateError);
            setShowErrorToast(true);
            setTimeout(() => {
                setShowErrorToast(false);
            }, 3000)
        }else if (fieldLabel !== "") {
            if(isPrimaryFieldModal){
                // check if modal is for dropdown or other fields to update dropdown values in schema
                if (isDropdown) {
                    frozenSchema.properties[selectedObjectApiName]["properties"][fieldApiName]["oneOf"] = dropdownValues;
                }
                frozenSchema.properties[selectedObjectApiName]["properties"][fieldApiName]["regexRuleName"] = regexName;
                frozenSchema.properties[selectedObjectApiName]["properties"][fieldApiName]["pattern"] = pattern;
                frozenSchema.properties[selectedObjectApiName]["properties"][fieldApiName]["errorMessage"] = errorMessage;
                frozenSchema.properties[selectedObjectApiName]["properties"][fieldApiName]["default"] = currentDefaultData;
                setSchema(JSON.parse(JSON.stringify(frozenSchema)));
                setIsPrimaryFieldModal(false);
                setFieldLabel("");
                setDefaultValue("")
                setFieldAPIName("");
                setDropdownValues([]);
                // refresh schema and drop area after updating schema and uischema
                setTimeout(() => {
                    setUISchema(uiSchema.refresh());
                    setIsdropAreaUpdated(true);
                }, 200)
            }else{
                setIsdropAreaUpdated(false);
                setFieldLabel(fieldLabel);
                let generalFieldAPIName = edittingFieldId;
                if (fieldApiName && !frozenSchema.properties[GENERAL]["properties"].hasOwnProperty(fieldApiName)) {
                    if(draggedItems.indexOf(edittingFieldId) > -1){
                        draggedItems[draggedItems.indexOf(edittingFieldId)] = fieldApiName;
                        setDraggedItems(draggedItems);
                    }
                    uiSchema.fieldApiNameAndControlIdMap.set(fieldApiName, currentUiSchemaId);
                    uiSchema.fieldApiNameAndControlIdMap.delete(edittingFieldId);
                    
                    uiSchema.controlsMap.get(currentUiSchemaId).scope = uiSchema.controlsMap.get(currentUiSchemaId).scope.replace(/\/([^\/]*)$/, '/'+fieldApiName);
                    
                    frozenSchema.properties[GENERAL]["properties"][fieldApiName] = frozenSchema.properties[GENERAL]["properties"][edittingFieldId];
                    generalFieldAPIName = fieldApiName;
                    delete frozenSchema.properties[GENERAL]["properties"][edittingFieldId];
                }
                // check if modal is for dropdown or other fields to update dropdown values in schema
                if (isDropdown) {
                    frozenSchema.properties[GENERAL]["properties"][generalFieldAPIName]["oneOf"] = dropdownValues;
                }
                // updated field title in schema
                frozenSchema.properties[GENERAL]["properties"][generalFieldAPIName]["title"] = fieldLabel;
                frozenSchema.properties[GENERAL]["properties"][generalFieldAPIName]["regexRuleName"] = regexName;
                frozenSchema.properties[GENERAL]["properties"][generalFieldAPIName]["default"] = currentDefaultData;
                frozenSchema.properties[GENERAL]["properties"][generalFieldAPIName]["pattern"] = pattern;
                frozenSchema.properties[GENERAL]["properties"][generalFieldAPIName]["errorMessage"] = errorMessage;
                setSchema(JSON.parse(JSON.stringify(frozenSchema)));
                setFieldLabel("");
                setFieldAPIName("");
                setDefaultValue("");
                setDropdownValues([]);
                setToggleForGenericModal(false);
                // refresh schema and drop area after updating schema and uischema
                setTimeout(() => {
                    setUISchema(uiSchema.refresh());
                    setIsdropAreaUpdated(true);
                }, 200)
            }
        }
    }

    /**
     * This method manipulates schema and uischema passed by child component 
     * @param event 
     */
    const onGenericSchemaUpdateListener = (event : any) => {
        setFieldAPIName('');
        let schema = event.detail.schema;
        let uischema = event.detail.uischema;
        //check if editing field is dropdown
        if(schema["oneOf"] !== undefined && uischema['scope'].includes(GENERAL) && schema["oneOf"].length > 0){
            setDropdownValues(schema['oneOf']);
            setFieldLabel(schema['title'])
            setIsDropdown(true);
        }else if(schema["oneOf"] !== undefined && uischema['scope'].includes(selectedObjectApiName) && schema["oneOf"].length > 0){
            setDropdownValues(schema['oneOf']);
            setFieldLabel(schema['title'])
            setIsDropdown(true);
            setIsTextfield(false);
            setIsDateField(false);
        } 
        else{
            setIsDropdown(false);
            setIsTextfield(true);
            setIsDateField(false);
            setFieldLabel('')
            setDropdownValues([])
        }
        schema.hasOwnProperty('default') ? setDefaultValue(schema.default) : setDefaultValue('');
        if (schema.hasOwnProperty('oneOf')) {
            setIsDropdown(true);
            setIsTextfield(false);
        }
        if(schema.hasOwnProperty('maxLength')){
            setIsTextfield(true);
            setIsBlank(false);
        }
        if(schema['format'] === 'date-time' || schema['format'] === 'date'){
            setIsDateField(true);
            setIsTextfield(false);
            setIsDropdown(false);
        }
        if(uischema.type === 'lookup'){
            setIsDateField(false);
            setIsTextfield(false);
            setIsDropdown(true);
        }
        if(uischema.type === 'select'){
            setIsDateField(false);
            setIsTextfield(false);
            setIsDropdown(true);
        }
        if(event.detail.uischema['type'] === 'blank'){
            setIsBlank(true);
        }
        // check if editing field is general or not
        if (uischema.scope.includes(GENERAL)) {
            setIsdropAreaUpdated(false);
            let fieldId = uischema.scope.split("/").pop(); // gets the id of field i.e G0, G1...
            setEdittingFieldId(fieldId); // stores the id of field being edited
            setCurrentUiSchemaId(uischema.options.id); // stores the id of field being edited
            setGenericFieldTitle(schema["title"]);
            // Check if uischema scope matches the pattern G1, G2, G3, G4, etc.
            const pattern = /^G\d+$/;
            //Only set the field API when uischema scope does not has G1, G2 , G3 etc
            if (!pattern.test(uischema.scope.split('/').pop())) {
                setFieldAPIName(fieldId);
                setFieldLabel(schema["title"]);
            }
            setToggleForGenericModal(true); // Open the modal for editting field label and values
            setValidations({regexRuleName:schema['regexRuleName'],pattern:schema['pattern'],errorMessage:schema['errorMessage']})
            setTimeout(() => {
                setIsdropAreaUpdated(true);
            }, 100);
        }
        //check if editing field is SObject or not
        if(uischema.scope.includes(selectedObjectApiName)){
            //check whether the field is context field
            if(uischema.scope.includes(contextObjectRelationshipName) && contextObjectRelationshipName !== ""){
                setIsPrimaryFieldModal(false);
            }else{
                let apiName = uischema.scope.split("/").pop(); 
                setPrimaryFIeldApiName(apiName);
                setFieldLabel(schema["title"]);
                setGenericFieldTitle(schema["title"]);
                setValidations({regexRuleName:schema['regexRuleName'],pattern:schema['pattern'],errorMessage:schema['errorMessage']})
                setIsPrimaryFieldModal(true);
            }
        }
    }
    const [schema, setSchema] = useState<any>(); // schema that is used on runtime
    const [frozenSchema, setFrozenSchema] = useState<any>(); // schema that is used on formbuilder

    //TODO: get schema from server for safety or audit based on type
    useEffect(() => {
        if(selectedObjectApiName){
            if(!isObjectSelected){
                setToggleForSelectObject(false);
                setIsObjectSelected(true);
                getMetadata(selectedObjectApiName, contextObjectApiName);
            }

            if(isLoaded){
                let schemaBuilder = new CQFSchemaBuilder();
                sObjectSchema.objectType = selectedObjectApiName;
                sObjectSchema.fields = selectedObjectFieldsAPIList;
                if(contextObjectApiName) {
                    sObjectSchema.context = {
                        contextObj: contextObjectApiName,
                        relationshipName: contextObjectRelationshipName
                    };
                    setsObjectSchema(sObjectSchema);
                }
                setIsdropAreaUpdated(false);
                schemaBuilder.apiCall(sObjectSchema, 'object-info', []).then(result => {
                    // first we store all required fields which are made at its schema level in object
                    let requiredFieldsList : any = result.properties[Object.keys(result.properties)[0]].required;
                    setRequiredFieldsAtSchemaLevel(requiredFieldsList);
        
                    // adding General with empty properties under schema properties
                    result.properties[GENERAL] = {
                        'properties': {}
                    }
        
                    // emptying it since user may not want(drag) all fields(which made required at field level) in an object
                    Object.keys(result.properties).map((key,index) => {
                        result.properties[Object.keys(result.properties)[index]].required = [];
                        if(currentFormData && key !== GENERAL){
                            
                            if(currentFormData.schema && currentFormData.schema && currentFormData.schema.properties[''+key]){
                                result.properties[Object.keys(result.properties)[index]].required = currentFormData.schema.properties[''+key]['required'];
                                result.properties[selectedObjectApiName].properties = {...result.properties[selectedObjectApiName].properties, ...currentFormData.schema.properties[selectedObjectApiName].properties};
                            }
                            if(Object.keys(currentFormData.schema.definitions).length > 0){
                                result.definitions = {...result.defintions , ...currentFormData.schema.definitions}
                            }
                        }
                        if(key === GENERAL && currentFormData && currentFormData.schema){
                            result.properties[Object.keys(result.properties)[index]] = currentFormData.schema.properties[GENERAL];
                        }
                        
                    })
                    setSchema(result);
                    setFrozenSchema(JSON.parse(JSON.stringify(result))); 
                    // check if context object is selected
                    if (contextObjectApiName) {
                        // update schema object type to fetch object information from org
                        sObjectSchema.objectType = contextObjectApiName;
                        sObjectSchema.fields = contextObjectFieldsAPIList;
                        delete sObjectSchema.context;
                        delete sObjectSchema.child;
                        setsObjectSchema(sObjectSchema);
                        schemaBuilder.apiCall(sObjectSchema, 'object-info', []).then(childContext => {
                            let requiredContextFieldsList: any = childContext.properties[Object.keys(childContext.properties)[0]].required;
                            setRequiredContextFieldsAtSchemaLevel(requiredContextFieldsList);
                            Object.assign(result.definitions, childContext.properties)
                            setSchema(result);
                            setFrozenSchema(JSON.parse(JSON.stringify(result))); 
                            // changed object type to main object after fetching context object information
                            sObjectSchema.objectType = selectedObjectApiName;
                            setsObjectSchema(sObjectSchema);
                            setIsdropAreaUpdated(true);
                        })
                    } else {
                        setIsdropAreaUpdated(true);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
            
        }  
    }, [sObjectSchema, selectedObjectFieldsAPIList, currentFormData, selectedObjectApiName, isLoaded, isObjectSelected]);

    /**
     * This useeffect gets trigger when schema is updated
     */
    useEffect(() => {
        // listens event dispatched by child component
        document.addEventListener("cqupdateschema", onGenericSchemaUpdateListener);
        return () => {
            document.removeEventListener("cqupdateschema", onGenericSchemaUpdateListener);
        }
    }, [schema])

    /**
     * This listener gets called when rows are updated on table
     * @param event 
     */
    const onTableRowsUpdateListener = (event:any) => {
        let totalRows:any = event.detail;
        Object.assign(schemaTotalTableRows, totalRows);
        setSchemaTotalTableRows(schemaTotalTableRows);
    }

    /**
     * This listener used to update table id and header in schema properties of general/unmapped field 
     * @param event 
     */
    const onGridUpdateListener = (event:any) => {
        setIsdropAreaUpdated(false); 
        let gridIdDetails :any = event.detail;
        numberOfGrid.forEach(element => {
            if(element.tableId === gridIdDetails.oldGridId){
                element.tableId = gridIdDetails.newGridId;
            }
        });
        setSectionsInfo(JSON.parse(JSON.stringify(sectionsInfo).replace(gridIdDetails.oldGridId, gridIdDetails.newGridId)));
        setNumberOfGrid(numberOfGrid)
        Object.assign(schemaTableHeader,{[event.detail.newGridId]: {'title': event.detail.header}});
        setSchemaTableHeader(schemaTableHeader);
        if(draggedItems.indexOf(gridIdDetails.oldGridId) > -1){
            draggedItems[draggedItems.indexOf(gridIdDetails.oldGridId)] = gridIdDetails.newGridId;
            setDraggedItems(draggedItems);
        }
        setTimeout(() => {
            setIsdropAreaUpdated(true);
        }, 200);
    }

    /**
     * This listener used to show error toast message when duplicate api name is tried to update in schema
     * @param event 
     */
    const onApiErrorListener = (event:any) => {
        setErrorToastMessage(event.detail.message);
        setShowErrorToast(true);
        setTimeout(() => {
            setShowErrorToast(false);
        }, 3000)
    }

    /**
     * This useffect gets trigger when uischema is updated by table
     */
    useEffect(() => {
        // listens event dispatched by child component
        document.addEventListener("cqupdateuischema", onTableRowsUpdateListener);
        return () => {
            document.removeEventListener("cqupdateuischema", onTableRowsUpdateListener);
        }
    }, [uiSchema.uischema, schemaTotalTableRows])

    /**
     * Update Section Information
     */
    useEffect(() => {
        // listens event dispatched by child component
        document.addEventListener("cqupdateuischemasection", onSectionUISchemaUpdateListener);
        return () => {
            document.removeEventListener("cqupdateuischemasection", onSectionUISchemaUpdateListener);
        }
    }, [sectionsInfo, uiSchema, frozenSchema])


    /**
     * rearranges the section Id if section delete action is performed
     * @param uischema 
     * @param index 
     */
    const rearrangeSectionIndex = (uischema, index) => {
        if(Array.isArray(uischema)){
            uischema.forEach(item => {
                if(item.hasOwnProperty('sectionId')){
                    if(Number(item.sectionId) > index) item.sectionId = (Number(item.sectionId) - 1).toString();
                }
            })
        }
    }

    //TODO: get data from server based on different data
    const [data, setData] = useState<any>({});
    const [queries, setQueries] = useState<any>([]);

    /**
     * Handle actions on section
     * @param event 
     * @returns 
     */
    const onSectionUISchemaUpdateListener = async(event : any) => {
        if(!event.detail.hasOwnProperty('value') && !event.detail.hasOwnProperty('type')) return undefined;
        let index : any = event.detail.value.index;
        let data : any = event.detail.value.data;
        switch(event.detail.type){
            case 'section' :
                if(Array.isArray(uiSchema.uischema)){
                    uiSchema.uischema[index].elements[0].label = data;
                }else{
                    uiSchema.uischema.elements[index].elements[0].label = event.detail.value.data;
                }
            break;
            case 'delete':
                setReload(false);
                if(uiSchema.uischema.hasOwnProperty('type')){
                    if(uiSchema.uischema.type === "VerticalLayout"){
                        processSectionFields(uiSchema.uischema.elements[index]);
                        uiSchema.uischema.elements.splice(index,1);
                        if(uiSchema.uischema.elements.length) rearrangeSectionIndex(uiSchema.uischema.elements, index);
                    }
                }else if(Array.isArray(uiSchema.uischema)){
                    processSectionFields(uiSchema.uischema[index]);
                    uiSchema.uischema.splice(index,1);
                    if(uiSchema.uischema) rearrangeSectionIndex(uiSchema.uischema, index);
                }
                let itemsToRomve = sectionsInfo.splice(index,1);
                itemsToRomve.forEach(sectionTables => {
                    sectionTables.forEach(table => {
                        onDeleteTableListener({detail:{id: table.tableId}})
                    })
                });
                if(uiSchema?.uischema.hasOwnProperty('elements')){
                    uiSchema?.uischema.elements.map(items=>
                      items.elements.map(sectionItems=>{
                       if(sectionItems.hasOwnProperty('section')){
                        if(sectionItems.section > parseInt(index)+1)
                           sectionItems.section = (sectionItems.section-1);
                      }
                    })
                    )
    
                    let filteredQueries:any=[];
                    let deletedQueries:any=[];
                    queries.map(queryProps => {
                        if(queryProps.sectionId !== index){  
                            if (queryProps?.sectionId > index) {
                                queryProps.sectionId = String(queryProps?.sectionId - 1);
                            }
                            filteredQueries.push(queryProps);
                        }else{
                            deletedQueries.push(queryProps);
                        }
                    })
                    if(deletedQueries.length !== 0){
                        let draggedDocs:any=selectedDocs
                        rightPanelItemsList.forEach((item) => {
                            if(item['type'] === "QueryField"){
                                let fieldsList : any = item['items']
                                fieldsList.forEach((fieldItem) => {
                                    deletedQueries.forEach(queryProps => {      
                                        if(fieldItem['id'] === queryProps.key){
                                            fieldItem['isDragged'] = false;
                                            if(draggedDocs.indexOf(fieldItem['value']) > -1){
                                                draggedDocs.splice(draggedDocs.indexOf(fieldItem['value']), 1);
                                            }
                                        }
                                    });
                                })
                            }
                        });
                    }
                    for(let i=0; i<selectedObjectFieldsList.length; i++){
                        let fieldItem : any = selectedObjectFieldsList[i];
                        deletedQueries.forEach(queryProps => {      
                            if(fieldItem['id'] === queryProps.key){
                                fieldItem['isDragged'] = false;
                            }
                        });
                    }
                    setSelectedObjectFieldsList(selectedObjectFieldsList);
                    const {tempData, startingData} : any = returnMappingAndStartingData(filteredQueries);
                    setData(tempData);
                    setQueries(filteredQueries);
                    setStartingDataCache(JSON.parse(JSON.stringify(startingData)));
                    setRightPanelItemsList(rightPanelItemsList);
                }
                setUISchema(uiSchema);
                setFrozenSchema(frozenSchema);
                setTimeout(() => {
                    setReload(true);
                }, 100)
            break;
        }
    }

    const returnMappingAndStartingData=(filteredQueries)=>{
        let startingData:any= [];
        let tempData = {};
        filteredQueries.map((items) => {
            startingDataCache?.some((data) => {
            let recordName;
            let relns;   
            if(isArray(data)){
               recordName= data[0]?.recordTrees[0]?.record.Name
               relns= data[0]?.recordTrees[0]?.relns
            }else{
              recordName= data?.recordTrees[0]?.record.Name
              relns= data?.recordTrees[0]?.relns
            }       
              if (items.filter.s_value === recordName) {
                startingData.push(data);
                return true;
                }
              });
              
          })

        if(startingData){
            startingData?.forEach((record, index)=>{
                if(filteredQueries){
                    let mapping = filteredQueries[index].mappingData;
                    if (mapping && record[0].recordTrees ) {
                        record[0].recordTrees.forEach( (startingDatum) => {
                            let transformedData = transformDataToClassicFormat(startingDatum);
                            fillInValues(tempData, transformedData, mapping);
                        });
                    }
                }
            });
        }
        return {tempData, startingData};
    }

    /**
     * This method process through uischema structure and remove fields
     * @param uischema 
     */
    const processSectionFields = (uischema) => {
        let itemsSection:any=[];
        if(uischema.type === "VerticalLayout"){
            uischema.elements.forEach((item) => {
                if(item.hasOwnProperty("type")){
                    if(item.type === "VerticalLayout"){
                        processSectionFields(item);
                    }
                    else if (item.type === "Group"){ 
                        item.elements.forEach((rowItems)=>{
                            rowItems.elements.forEach((sectionItems) => {
                                if(sectionItems.hasOwnProperty("type") && sectionItems.hasOwnProperty("options")){
                                    //evt.detail.uischema
                                    itemsSection.push({detail:{ uischema : { ...sectionItems}}});
                                }
                            })
                        })
                    }
                }
            })
        }
        if(itemsSection.length !== 0){
            itemsSection?.map(items=>{   
            onDeleteListener(items);
            })
        }
    }

    /**
     * This useffect gets trigger when table id is wanted to update in schema
     */
    useEffect(() => {
        // listens event dispatched by child component
        document.addEventListener("cqupdategridid", onGridUpdateListener);
        return () => {
            document.removeEventListener("cqupdategridid", onGridUpdateListener);
        }
    }, [numberOfGrid, sectionsInfo, frozenSchema, schema])

    /**
     * This useffect gets trigger when duplicate api name is given to table
     */
    useEffect(() => {
        // listens event dispatched by child component
        document.addEventListener("cqthrowduplicateapierror", onApiErrorListener);
        return () => {
            document.removeEventListener("cqthrowduplicateapierror", onApiErrorListener);
        }
    }, [])

    useEffect(() => {
        // listens event dispatched by child component
        document.addEventListener("cqdragtable", (evt:any) => {setDisableTableDrag(evt.detail.dragDisable)});
        return () => {
            document.removeEventListener("cqdragtable", (evt:any) => {setDisableTableDrag(evt.detail.dragDisable)});
        }
    }, [uiSchema.uischema, schema])
    


    const ajv = createAjv({
        allErrors: false,
        jsonPointers: false
    });
    ajv.validate = () => {
        return true;
    }


    // Method used to delete dragged item in left panel
    const onDeleteListener = (evt : any) => {   
        setIsdropAreaUpdated(false);
        let schemaToBeRemoved : any = evt.detail.uischema;
        let dropzoneIdWithIndex = uiSchema.controlIdWithDropzoneIdMap.get(schemaToBeRemoved.options.id)
        uiSchema.removeField(dropzoneIdWithIndex);
        let removedField : string = schemaToBeRemoved.hasOwnProperty('draggedFieldId') ? schemaToBeRemoved.draggedFieldId :  schemaToBeRemoved.scope.split("/").pop();
        Object.keys(frozenSchema.properties).forEach((key) => {
            if(key === GENERAL){
                if (evt.detail.uischema.scope.includes(GENERAL)) {
                    let generalFieldId : string = removedField;
                    let generalFieldRequiredIndex : number = schema.properties[GENERAL]['required'].indexOf(generalFieldId);
                    if(generalFieldRequiredIndex > -1){
                        frozenSchema.properties[GENERAL]['required'].splice(generalFieldRequiredIndex, 1);
                    }
                    delete frozenSchema.properties[GENERAL]["properties"][''+generalFieldId];
                }
            }else{
                let objectFieldIndexInRequiredList : number = frozenSchema.properties[""+key]['required'].indexOf(removedField);
                if( frozenSchema.properties[""+key].properties.hasOwnProperty(removedField) && frozenSchema.properties[""+key].properties[removedField].hasOwnProperty('default')) {
                    frozenSchema.properties[""+key].properties[removedField]['default'] = '';
                }
                if(objectFieldIndexInRequiredList > -1){
                    frozenSchema.properties[""+key]['required'].splice(objectFieldIndexInRequiredList, 1);
                }
            }
        });
        setFrozenSchema(JSON.parse(JSON.stringify(frozenSchema)));
        setSchema(JSON.parse(JSON.stringify(frozenSchema)));   
        setUISchema(uiSchema.refresh());

        rightPanelItemsList.forEach((item) => {
            if(item['type'] !== "QueryField"){
                let fieldsList : any = item['items']
                fieldsList.forEach((fieldItem) => {
                    if(fieldItem['isDragged']){
                        if(fieldItem['id'] === removedField){
                            fieldItem['isDragged'] = false;
                        }
                    }
                })
            }
        });

        for(let i=0; i<selectedObjectFieldsList.length; i++){
            let fieldItem : any = selectedObjectFieldsList[i];
            if(fieldItem['id'] === removedField){
                fieldItem['isDragged'] = false;
            }
        }
        if(draggedItems.indexOf(removedField) > -1){
            let fieldIndexInDraggedList : number = draggedItems.indexOf(removedField);
            draggedItems.splice(fieldIndexInDraggedList, 1);
            setDraggedItems(draggedItems);
        }
        setSelectedObjectFieldsList(selectedObjectFieldsList);
        setRightPanelItemsList(rightPanelItemsList);
        setTimeout(() => {
            setIsdropAreaUpdated(true);
        }, 100);
    }

    /**
     * Method to delete dragged table grid
     * @param evt 
     */
    const onDeleteTableListener = (evt: any) => {
        setIsdropAreaUpdated(false);
        const tableId : string = evt.detail.id;
        delete frozenSchema.definitions[tableId];
        let relnName = getRelationalAPIName(frozenSchema, tableId, selectedObjectApiName);
        delete frozenSchema.properties[selectedObjectApiName].properties[relnName];
        setFrozenSchema(frozenSchema);
        setSchema(JSON.parse(JSON.stringify(frozenSchema)));            
        if(Object.keys(generalData).length && generalData[tableId]){
            delete generalData[tableId];
            setGeneralData(generalData);
        }
        setSectionsInfo((prevState) => {
            return prevState.map((eachSectionTableInfo) => {
                return eachSectionTableInfo.filter(item => item.tableId !== tableId);
            })
        })
        let tableGrids = numberOfGrid.filter(item => item.tableId !== tableId);
        setSchemaTableColumns({});
        setSchemaTableHeader({});
        setNumberOfGrid(tableGrids);
        setTimeout(() => {
            setIsdropAreaUpdated(true);
        }, 100);
    }

    useEffect(() => {
        document.addEventListener("cqdeleteuischema", onDeleteListener);

        return () => {
            document.removeEventListener("cqdeleteuischema", onDeleteListener);
        }
    },[uiSchema, schema, selectedObjectFieldsList, rightPanelItemsList, frozenSchema]);

    
    useEffect(() => {
        
        document.addEventListener("cqdeletetable", onDeleteTableListener);

        return () => {
            document.removeEventListener("cqdeletetable", onDeleteTableListener);
        }
    },[frozenSchema, numberOfGrid, generalData]);

    useEffect(() => {
        /**
         * This method used to delete dragged document and make it enable for draggining again in right panel
         * @param evt 
         */
        const onDeleteDocListener = async(evt : any) => {
            setIsdropAreaUpdated(false);
            let docId : string = evt.detail.docId;
            let filteredQueries:any=[];
            queries.map(queryProps => {
                if(queryProps.key !== docId){
                    filteredQueries.push(queryProps);
                }
            })       
            setStartingDataCache([]);
            let lookupCacheDataList : any = {};
            if(!queries.length){
                setData({});
                setStartingDataCache([]);
                setLookupCacheData([]);
            }else{
                const {tempData, startingData} : any = returnMappingAndStartingData(filteredQueries);
                setData(tempData);
                setQueries(filteredQueries);
                setLookupCacheData(lookupCacheDataList);
                setStartingDataCache(JSON.parse(JSON.stringify(startingData)));
            }
            let draggedDocs : any = selectedDocs
            rightPanelItemsList.forEach((item) => {
                if(item['type'] === "QueryField"){
                    let fieldsList : any = item['items']
                    fieldsList.forEach((fieldItem) => {
                        if(fieldItem['id'] === evt.detail.docId){
                            fieldItem['isDragged'] = false;
                            if(draggedDocs.indexOf(fieldItem['value']) > -1){
                                draggedDocs.splice(draggedDocs.indexOf(fieldItem['value']), 1);
                            }
                        }
                    })
                }
            });

            for(let i=0; i<selectedObjectFieldsList.length; i++){
                let fieldItem : any = selectedObjectFieldsList[i];
                if(fieldItem['id'] === evt.detail.docId){
                    fieldItem['isDragged'] = false;
                }
            }
            setSelectedObjectFieldsList(selectedObjectFieldsList);
            setSelectedDocs(draggedDocs);
            setRightPanelItemsList(rightPanelItemsList);
            setUISchema(uiSchema.refresh());

            setTimeout(() => {
                setIsdropAreaUpdated(true);
            }, 100);
        }
        
        document.addEventListener("cqdeletedoc", onDeleteDocListener);

        return () => {
            document.removeEventListener("cqdeletedoc", onDeleteDocListener);
        }
    },[docIdAndStartingDataCacheMap, docIdAndLookupCacheMap, lookupCacheData, startingDataCache, rightPanelItemsList]);

    useEffect(() => {
        // Method used to make field required/readonly
        const onReadOnlyRequiredListener = (evt : any) => {
            setIsdropAreaUpdated(false);
            let currentUISchema : any = evt.detail.uischema;
            let fieldName : string = currentUISchema.scope.split("/").pop();
            if(evt.detail.type === formBuilderScreenStrings.requiredLabel){
                let requiredFieldsList : any = [];
                let requiredContextFieldsList : any = [];
                let key = "";
                if (evt.detail.uischema.scope.includes(GENERAL)) {
                    key = GENERAL;
                } else {
                    key = Object.keys(frozenSchema.properties)[0];
                }
                // added condition to check if incoming event is for context object field or main object field
                if (evt.detail.uischema.options.isReferenceField) {
                    requiredContextFieldsList = frozenSchema.definitions[contextObjectApiName].required;
                    if(requiredContextFieldsList.indexOf(fieldName) === -1 && evt.detail.value){
                        requiredContextFieldsList.push(fieldName);
                    }else if(requiredContextFieldsList.indexOf(fieldName) > -1 && !evt.detail.value){
                        let idx : number = requiredContextFieldsList.indexOf(fieldName);
                        requiredContextFieldsList.splice(idx, 1);
                    }
                    frozenSchema.definitions[contextObjectApiName].required = requiredContextFieldsList;
                } else {
                    requiredFieldsList = frozenSchema.properties[key].required;
                    if(requiredFieldsList.indexOf(fieldName) === -1 && evt.detail.value){
                        requiredFieldsList.push(fieldName);
                    }else if(requiredFieldsList.indexOf(fieldName) > -1 && !evt.detail.value){
                        let idx : number = requiredFieldsList.indexOf(fieldName);
                        requiredFieldsList.splice(idx, 1);
                    }
                    frozenSchema.properties[key].required = requiredFieldsList;
                }
                setFrozenSchema(frozenSchema);
                setSchema(JSON.parse(JSON.stringify(frozenSchema))); 
            }else if(evt.detail.type === formBuilderScreenStrings.readOnlyLabel){
                if(uiSchema['uischema'].hasOwnProperty("elements")){
                    uiSchema['uischema']['elements'].forEach((multipleSection) => {
                        multipleSection['elements'][0]['elements'].forEach((childUiSchema,index) => {
                            childUiSchema['elements'].forEach((fieldSchema) => {
                                if(fieldSchema.hasOwnProperty("elements")){
                                    fieldSchema['elements']?.forEach((childFieldSchema) => {
                                        handleReadOnlyProperty(childFieldSchema, currentUISchema, evt);
                                    })
                                }else{
                                    handleReadOnlyProperty(fieldSchema, currentUISchema, evt);
                                }
                            })
                        });
                    });
                }else{
                    uiSchema['uischema'].forEach((multipleSection) => {
                        multipleSection['elements'][0]['elements'].forEach((childUiSchema,index) => {
                            childUiSchema['elements'].forEach((fieldSchema,fieldIndex) => {
                                if(fieldSchema.hasOwnProperty("elements")){
                                    fieldSchema['elements']?.forEach((childFieldSchema,fieldIndex) => {
                                        handleReadOnlyProperty(childFieldSchema, currentUISchema, evt);
                                    })
                                }else{
                                    handleReadOnlyProperty(fieldSchema, currentUISchema, evt);
                                }
                            })
                        });
                    });
                }
                let readonlyFields : any = readOnlyFieldsList;
                if(evt.detail.value && readonlyFields.indexOf(fieldName) === -1){
                    readonlyFields.push(fieldName);
                }else if(!evt.detail.value && readonlyFields.indexOf(fieldName) > -1){
                    readonlyFields.splice(fieldName);
                }
                setReadOnlyFieldsList(readonlyFields);
                setUISchema(uiSchema);
            }
            setTimeout(() => {
                setIsdropAreaUpdated(true);
            }, 10);
        }
        
        document.addEventListener("cqreadonlyrequireduischema", onReadOnlyRequiredListener);

        return () => {
            document.removeEventListener("cqreadonlyrequireduischema", onReadOnlyRequiredListener);
        }
    },[uiSchema,schema,frozenSchema]);

    const handleReadOnlyProperty=(fieldSchema, currentUISchema, evt)=>{
        if(fieldSchema.options.id === currentUISchema.options.id){
            fieldSchema.options['readOnly'] = evt.detail.value;
            if(evt.detail.value){
                fieldSchema['rule'] = {
                    "effect": "DISABLE",
                    "condition": {
                        "scope": "#",
                        "schema": {}
                    }
                }
            }else{
                delete fieldSchema['rule'];
            }
        }
    }

    useEffect(() => {
        if(isDragged){
            setIsdropAreaUpdated(true);
            setIsDragged(false);
        }
    },[isDragged]);

    /**
     * This method used to set startingData and lookupCacheData as map values for the document that dragged
     * @param startingData 
     * @param lookupCache 
     * @param index 
     * @param docId 
     */
    const setLookupCacheAndStartingData = (startingData : any, lookupCache : any, docId : string) =>{
        let startingDataMap : any = docIdAndStartingDataCacheMap ? docIdAndStartingDataCacheMap : new Map();
        if(!startingDataMap.get(docId)){
            startingDataMap.set(docId, startingData);
            setDocIdAndStartingDataCacheMap(startingDataMap);
        }
        
        let lookupCacheDataMap : any = docIdAndLookupCacheMap ? docIdAndLookupCacheMap : new Map();
        if(!lookupCacheDataMap.get(docId)){
            lookupCacheDataMap.set(docId, lookupCache);
            setDocIdAndLookupCacheMap(lookupCacheDataMap);
        }
    }

    const refreshData = async (query, index) => {

        try {
            let response = await sfAPI.getStartingData(props.recordId, JSON.stringify(query), lookupCacheString);
            if(response.hasOwnProperty('lookupCacheData') && Object.keys(response?.lookupCacheData).length){
                setLookupCacheAndStartingData(response.startingData, response.lookupCacheData, query.key);
                startingDataCache[index] = response.startingData;
                let tempData = JSON.parse(JSON.stringify(data)) || {};
                cqFormJSONProcessor.queries = queries;
                let returnObj :any = cqFormJSONProcessor.getDataLookupCacheAndStartingDataAfterProcessing(response.startingData, response.lookupCacheData, lookupCacheData, tempData);

                setData(returnObj['tempData']);
                setLookupCacheData(returnObj['lookupCacheDataTobeUpdated']);
                setStartingDataCache(JSON.parse(JSON.stringify(startingDataCache)));
            }else{
                // show error message when incorrect formatted doc is dragged.
                setIsdropAreaUpdated(false);
                queries.splice(index, 1);
                let draggedDocs : any = selectedDocs
                rightPanelItemsList.forEach((item) => {
                    if(item['type'] === "QueryField"){
                        let fieldsList : any = item['items']
                        fieldsList.forEach((fieldItem) => {
                            if(fieldItem['id'] === query.filter.s_value){
                                fieldItem['isDragged'] = false;
                                if(draggedDocs.indexOf(fieldItem['value']) > -1){
                                    draggedDocs.splice(draggedDocs.indexOf(fieldItem['value']), 1);
                                }
                            }
                        })
                    }
                });
                setQueries(queries);
                setSelectedDocs(draggedDocs);
                setRightPanelItemsList(rightPanelItemsList);
                setErrorToastMessage(formBuilderScreenStrings.documentErrorMessage);
                setShowToast(true);
                setTimeout(() => {
                    setIsdropAreaUpdated(true);
                    setShowToast(false);
                }, 3000);
            }
        } catch(ex) {
            console.error(ex);
        }
    }

    // This methods checks if color field is available on result type value or not
    const handleColorFieldOnDocumentSelection = async () => {
        let resultTypeValueObjectMetadata = await sfAPI.getSeletectedObjectMetadata(RESULT_TYPE_VALUE_OBJECT);
        let fieldAvailable = sfAPI.checkFieldAvailability(resultTypeValueObjectMetadata.fields, COLOR_FIELD);
        isColorFieldAvailable.current = fieldAvailable;
    }

    // Method is called when item is dropped into left panel from right panel
    const handleOnDragEnd = (result: any) => {
        if(result.reason === 'DROP' && result.destination) {
            // Assuming 'table' is a unique identifier for table items
            const isTable = result.source.droppableId.includes('table');

            const isField = result.source.droppableId.includes('ObjectField') || result.source.droppableId.includes('ContextObjectField') ; 
            // Check if the destination is not the same as the source (i.e., trying to drop in a different zone)
            if (isTable && result.source.droppableId!== result.destination.droppableId) return ;// Prevent dropping tables in non-table zones
            if (isField && result.destination.droppableId.includes('table')) return; //Prevent dropping fields in table drop zone
            
            setIsdropAreaUpdated(false);
            let dropZoneId = result.destination.droppableId;
            let droppedField = result.draggableId;
            let sourceDropZoneId = isTable ? result.source.droppableId.split('-')[0]:result.source.droppableId;
            switch (result.type) {
                case 'FIELD':
                    let fieldsList : any = [];
                    let draggedFieldUISchema : any = {};
                    
                    rightPanelItemsList.forEach((item: any) => {
                        if(item['type'] === "ObjectField"  || item['type'] === "ContextObjectField"){
                            fieldsList = item['items']
                            fieldsList.forEach((fieldItem: any) => {
                                if(fieldItem['id'] === droppedField){
                                    fieldItem['isDragged'] = true;
                                    draggedFieldUISchema = fieldItem['fieldUISchema'];
                                    draggedFieldUISchema['draggedFieldId'] = droppedField;
                                    draggedFieldUISchema.options['isReferenceField'] = fieldItem['isReferenceField'];
                                    if(fieldItem['isReferenceField']) {
                                        draggedFieldUISchema.options['readOnly'] = true;
                                        draggedFieldUISchema['rule'] = {
                                            "effect": "DISABLE",
                                            "condition": {
                                                "scope": "#",
                                                "schema": {}
                                            }
                                        }
                                    }
                                }
                            })
                        }
                    });
                    setRightPanelItemsList(rightPanelItemsList);
                    selectedObjectFieldsList.forEach((item: any) => {
                        if(item['id'] === droppedField){
                            item['isDragged'] = true;
                        }
                    })
                    setSelectedObjectFieldsList(selectedObjectFieldsList);
                    if(uiSchema.isSourceFormField(sourceDropZoneId)) {
                        uiSchema.moveField(droppedField, sourceDropZoneId, dropZoneId);
                    } else if(uiSchema.isContextObjectFieldPanel(sourceDropZoneId)) {
                        let contextObject = {
                            contextObjectApiName,
                            contextObjectRelationshipName
                        }
                        const fieldToAdd = droppedField.includes(contextObjectRelationshipName+'.')? droppedField.split(contextObjectRelationshipName+'.')[1] : droppedField;
                        uiSchema.addField(fieldToAdd, dropZoneId, draggedFieldUISchema, selectedObjectApiName, contextObject);
                        if(draggedItems.indexOf(droppedField) === -1){
                            draggedItems.push(droppedField);
                            setDraggedItems(draggedItems);
                        }
                    } else if(uiSchema.isObjectFieldPanel(sourceDropZoneId)) {
                        if(frozenSchema.properties[selectedObjectApiName].properties[droppedField].hasOwnProperty('dependent')){
                            uiSchema.addField(droppedField, dropZoneId, draggedFieldUISchema, selectedObjectApiName, undefined, frozenSchema.properties[selectedObjectApiName].properties[droppedField]);
                        }else{
                            uiSchema.addField(droppedField, dropZoneId, draggedFieldUISchema, selectedObjectApiName);
                        }
                        if(draggedItems.indexOf(droppedField) === -1){
                            draggedItems.push(droppedField);
                            setDraggedItems(draggedItems);
                        }
                    } else if(uiSchema.isGeneralFieldPanel(sourceDropZoneId)) {
                        let properties = {};
                        let uniqueId = "";
                        let uischemaTypeObj = {};
                        let tableUniqueId = (droppedField.split("")[0] + '_Table_' + Number(Object.keys(frozenSchema.definitions).length + 1));
                        Object.keys(frozenSchema.definitions).forEach(() => {
                            if(Object.keys(frozenSchema.definitions).includes(tableUniqueId)){
                                tableUniqueId = droppedField.split("")[0] + '_Table_' + Number(Number(tableUniqueId.split('_')[2])+ 1);
                            }
                        })
                        let generalFieldsKeys = Object.keys(frozenSchema.properties[GENERAL]["properties"]);
                        uniqueId = (droppedField.split("")[0] + generalFieldsKeys.length); // creating unique id from dropped fields length e.g G0, G1..,G5...
                        if(draggedItems.indexOf(uniqueId) === -1){
                            draggedItems.push(uniqueId);
                            setDraggedItems(draggedItems);
                        }
                        // check the id of incoming field and assign properties accordingly
                        switch(droppedField) {
                            case "G0":
                                properties = {
                                    "title": "Dropdown",
                                    "type": "string",
                                    "oneOf": []
                                }
                                uischemaTypeObj = { "type" : "select", "options" : {"edited": false, "showLabel": true}}; 
                                break;
                            case "G1":
                                properties = {
                                    "title": "Text Field",
                                    "type": "string",
                                    "maxLength": 255,
                                    "default": "",
                                    "regexRuleName": "",
                                    "pattern": "",
                                    "errorMessage": "",
                                }
                            break;
                            case "G2":
                                properties = {
                                    "title": "Date Field",
                                    "type": "string",
                                    "default": "",
                                    "format": "date-time"
                                }
                            break;
                            case "G3":
                                properties = {
                                    "title": "YES/NO Field",
                                    "type": "string",
                                    "oneOf": [
                                        {
                                            "const": "YES",
                                            "title": "YES"
                                        },
                                        {
                                            "const": "NO",
                                            "title": "NO"
                                        }
                                    ],
                                    "default": ""
                                }
                                uischemaTypeObj = { "type" : "select", "options" : {"edited": false, "showLabel": true}}; 
                            break;
                            case "G5":
                                properties = {
                                    'title' : 'Blank Space',
                                    'type': 'string'
                                }
                                uischemaTypeObj = {"type" : "blank"}
                            break;
                            case "G4":
                                if(dropZoneId.includes('table')) {                            
                                    properties = {
                                        title: formBuilderScreenStrings.tableHeader,
                                        type: 'object',
                                        format:'table',
                                        properties: getFormattedProperties(initialColumnData.current)
                                    }
                                    handleGridClick(tableUniqueId, dropZoneId.split('-').pop());
                                }
                                uischemaTypeObj = { "type" : "select", "options" : {"edited": false, "showLabel": true}}; 
                            break;
                            default:
                                if(droppedField.includes('-') && dropZoneId.includes('table')) {
                                    handleGridUpdate(result);
                                }
                                properties = {}
                        }
                        
                        // add item description in schema too                      
                        if(droppedField !== 'G4') {
                            frozenSchema.properties[GENERAL]["properties"][uniqueId] = properties;   
                            uiSchema.addGeneralField(uniqueId, dropZoneId, uischemaTypeObj);
                        }else{
                            frozenSchema.definitions[tableUniqueId] = { 'type' : 'object', 'properties' : { [tableUniqueId] : properties}}
                            let relnAPIName = `${tableUniqueId}__r`
                            const relnPattern = {
                                "items": {
                                    "$ref": `#/definitions/${tableUniqueId}`
                                },
                                "title": "",
                                "type": "array"
                            }
                            frozenSchema.properties[selectedObjectApiName]["properties"][relnAPIName] = relnPattern;
                            
                            //check if scehmaTotaltableRows contain the deleted row or not. if it contain then reset the row to initial row
                            if(schemaTotalTableRows.hasOwnProperty(tableUniqueId)){
                                schemaTotalTableRows[tableUniqueId].totalRows = initialRowData.current.length;
                                setSchemaTotalTableRows(schemaTotalTableRows);
                            }
                        }
                        setSchema(JSON.parse(JSON.stringify(frozenSchema)));
                    }
                    setIsDragged(true)
                    setUISchema(uiSchema.refresh());
                    break;
                case 'DOCUMENTS':
                    // check if document exists etc for mock we are just pushing the value
                    let query =
                    {
                        "mappingData": {
                            "cqext__SQX_Safety_Inspection__c": {
                                "cqext__SQX_Safety_Inspection_Criteria__r": {
                                    "cqext__SQX_Safety_Inspection_Criteria__c": {
                                        "cqext__SQX_Safety_Checklist__r": {
                                            "cqext__SQX_Safety_Checklist__c": {
                                                "cqext__Response_Type__c": "$currentRecord.cqext__SQX_Response_Type__c",
                                                "cqext__Response_label__c": "$currentRecord.cqext__Question_Title__c",
                                                "cqext__Question_Type__c": "$currentRecord.cqext__Question_Type__c",
                                                "cqext__Comment__c": "$currentRecord.cqext__Comment__c",
                                                "cqext__Weight__c": "$currentRecord.cqext__Weight__c"
                                            },
                                            "source": "$context.cqext__SQX_Questions__r"
                                        },
                                        "cqext__Criteria_Title__c": "$context.cqext__Section_Title__c"
                                    },
                                    "source": "$context.cqext__SQX_Sections__r"
                                },
                                "attributes": {
                                    "type": "cqext__SQX_Safety_Inspection__c"
                                },
                                "cqext__Inspection_Type__c": "$context.cqext__Inspection_Type__c",
                                "cqext__SQX_Checklist__c": "$documentname",
                                "cqext__Inspection_Title__c": "$context.cqext__Inspection_Title__c",
                                "cqext__Start_Date__c": "$context.cqext__Start_Date__c",
                                "cqext__End_Date__c": "$context.cqext__End_Date__c",
                                "cqext__SQX_Location__c": "$context.cqext__SQX_Location__c",
                                "cqext__Assignee_Type__c": "User",
                                "cqext__SQX_Assignee__c": "$currentuser.Name"
                            }
                        },
                        "relationships": [
                            {
                                "relationships": [
                                    {
                                        "orderByDirection": "ASC",
                                        "orderbyFields": [
                                            "cqext__Order_Score__c"
                                        ],
                                        "connectionField": "cqext__SQX_Section__c",
                                        "fields": [
                                            "cqext__Question_Title__c",
                                            "cqext__Question_Type__c",
                                            "cqext__SQX_Response_Type__c",
                                            "cqext__Analysis_Code__c",
                                            "cqext__Order_Score__c",
                                            "cqext__Weight__c"
                                        ],
                                        "objectType": "cqext__SQX_Question__c"
                                    }
                                ],
                                "orderByDirection": "ASC",
                                "orderbyFields": [
                                    "Name",
                                    "cqext__Section_Title__c"
                                ],
                                "connectionField": "cqext__SQX_Controlled_Document__c",
                                "fields": [
                                    "Id",
                                    "cqext__Section_Title__c"
                                ],
                                "objectType": "cqext__SQX_Section__c"
                            }
                        ],
                        "orderByDirection": "ASC",
                        "orderByFields": [
                            "Name"
                        ],
                        "filter": {
                            "field": "Name",
                            "operator": "eq",
                            "s_value": droppedField
                        },
                        "fields": [
                            "Id",
                            "Name"
                        ],
                        "objectType": "compliancequest__SQX_Controlled_Document__c",
                        "type": "DOCUMENTS",
                        "key": droppedField
                    };
                    let draggedDocs : any = selectedDocs;
                    rightPanelItemsList.forEach((item) => {
                        if(item['type'] === "QueryField"){
                            let fieldsList : any = item['items']
                            fieldsList.forEach((fieldItem) => {
                                if(fieldItem['id'] === droppedField){
                                    fieldItem['isDragged'] = true;
                                    draggedDocs.push(fieldItem['value']);
                                }
                            })
                        }
                    });
                    selectedObjectFieldsList.forEach((item: any) => {
                        if(item['id'] === droppedField){
                            item['isDragged'] = true;
                        }
                    })
                    setSelectedObjectFieldsList(selectedObjectFieldsList);
                    setIsDragged(true)
                    setSelectedDocs(draggedDocs);
                    let queryIndex = queries.length;
                    query['sectionId'] = result.destination.droppableId.split('-')[1];
                    queries.push(query);
                    refreshData(query, queryIndex);
                    setUISchema(uiSchema.refresh());
                    break;
            }
        }
    }

    /**
     * This method is used to filter fields and documents
     * @param event 
     */
    const filterFieldsAndDocs = (event : any) =>{
        setIsFieldsLoaded(false);
        let filteredFields : any = [];
        // fiter for both mapping fields and documents
        selectedObjectFieldsList.forEach((item, index) => {
            let fieldVal : string = item['label'];
            if(fieldVal.toLowerCase().includes(event.target.value.toLowerCase().trim())){
                let obj = {}
                obj['item'] = item;
                if(item['type'] === 'DOCUMENT'){
                    obj['index'] = 3;
                }else if(item['type'] === 'FIELD'){
                    obj['index'] = 1;
                }
                
                filteredFields.push(obj);
            }
        });
        // filter for context fields
        const contextObjectFilteredFieldsList : any = contextObjectFieldsList.filter((item) => {
            const fieldVal : string = item['label'];
            return fieldVal.toLowerCase().includes(event.target.value.toLowerCase().trim())
        })

        let fieldsList : any = rightPanelItemsList;
        fieldsList[2]['items'] = contextObjectFilteredFieldsList;
        if(filteredFields.length){
            let filteredFieldItem : any = [];
            let filteredDocItems : any = [];
            filteredFields.forEach((itm, idx) => {
                if(itm['index'] === 1){
                    filteredFieldItem.push(itm['item']);
                }else if(itm['index'] === 3){
                    filteredDocItems.push(itm['item']);
                }
            });
            fieldsList[1]['items'] = filteredFieldItem;
            if(selectedObjectApiName.includes(SQX_Safety_Inspection)){
                fieldsList[3]['items'] = filteredDocItems;
            }
        }else if(event.target.value.trim() === ''){
            fieldsList[1]['items'] = selectedObjectFieldsList;
        }else{
            fieldsList[1]['items'] = []; 
            if(selectedObjectApiName.includes(SQX_Safety_Inspection)){
                fieldsList[3]['items'] = []; 
            }
        }
        setRightPanelItemsList(fieldsList);
        setTimeout(() => {
            setIsFieldsLoaded(true);
        },300);
    }

    /**
     * This method is called when user clicks the Add/Edit logo and popups the window to upload logo/image.
     */
    const uploadLogo = () => {
        let logoInputElement : any = document.getElementById('logo-input');
        logoInputElement.click();
    }


    /**
     * This method is used to remove logo from UI
     */
    const deleteLogo = () => {
        setIsLogoUpdated(false);
        setImgSrc('');
        onLogoChangeEvent({detail:{imgSrcString:''}})
        // using timeout to rest the hidden input(type=file) element  
        setTimeout(() => {
            setIsLogoUpdated(true);
        }, 100);
    }

    /**
     * This method will process the uischema and add table ui schema in proper index
     */
    const processTableUISchema = (uischema : any, index, grid) => {
        let gridInformation = {
            type: 'table',
            label: grid.tableId,
            scope: `#/properties/${selectedObjectApiName}/properties/${getRelationalAPIName(frozenSchema, grid.tableId, selectedObjectApiName)}`,
            options: {
                "rowsCount": schemaTotalTableRows[grid.tableId] ? schemaTotalTableRows[grid.tableId].totalRows: initialColumnData.current.length,
                "columnWidth": schemaTotalTableRows[grid.tableId]? schemaTotalTableRows[grid.tableId].columnWidth: {} 
            }
        }
        if(Array.isArray(uischema)){
            uischema[index].elements.push(gridInformation);
        }else{
            uischema.elements.push(gridInformation);
        }
        return uischema;
    }   
    
    /**
     * Method used to process formDef and invoke method to save the JSON
     */
    const saveFormData = async () =>{
        let schemaProps : any = frozenSchema['properties'];
        let finalRequiredFieldsList : any = schemaProps[Object.keys(schemaProps)[0]].required;
        if(finalRequiredFieldsList.length){
            for(let i=0;i<finalRequiredFieldsList.length;i++){
                if(blacklistFieldToAvoidRequiredAtSchema.indexOf(finalRequiredFieldsList[i]) > -1){
                    let idx = blacklistFieldToAvoidRequiredAtSchema.indexOf(finalRequiredFieldsList[i]);
                    blacklistFieldToAvoidRequiredAtSchema.splice(idx, 1);
                }
            }
        }


        let finalRequiredContextFieldsList : any  = [];
        if(contextObjectApiName) {
            finalRequiredContextFieldsList = frozenSchema.definitions[contextObjectApiName].required;
        }
        // if an item(field) in requiredFieldsAtSchemaLevel is an already dragged field and it is not made as required at wrapper component level then push that item schema required array
        for(let i=0; i<requiredFieldsAtSchemaLevel.length ; i++){
            if(draggedItems.indexOf(requiredFieldsAtSchemaLevel[i]) > -1 && finalRequiredFieldsList.indexOf(requiredFieldsAtSchemaLevel[i]) === -1 && blacklistFieldToAvoidRequiredAtSchema.indexOf(requiredFieldsAtSchemaLevel[i]) === -1){
                finalRequiredFieldsList.push(requiredFieldsAtSchemaLevel[i]);
            }
        }
        // if an item(field) in requiredContextFieldsAtSchemaLevel is an already dragged context field and it is not made as required at wrapper component level then push that item schema required array
        for(let i=0; i<requiredContextFieldsAtSchemaLevel.length ; i++){
            if(draggedItems.indexOf(contextObjectRelationshipName+'.'+requiredContextFieldsAtSchemaLevel[i]) > -1 && finalRequiredContextFieldsList && finalRequiredContextFieldsList.indexOf(requiredContextFieldsAtSchemaLevel[i]) === -1){
                finalRequiredContextFieldsList.push(requiredContextFieldsAtSchemaLevel[i]);
            } else if(draggedItems.indexOf(contextObjectRelationshipName+'.'+requiredContextFieldsAtSchemaLevel[i]) === -1) { // check if dragged item is listed in required fields and remove if any extra fields are listed inside schema required fields
                let itemIndex = finalRequiredContextFieldsList.indexOf(requiredContextFieldsAtSchemaLevel[i]);
                finalRequiredContextFieldsList.splice(itemIndex,1);
            }
        }
        schemaProps[Object.keys(schemaProps)[0]].required = finalRequiredFieldsList;
        if(contextObjectApiName) {
           frozenSchema.definitions[contextObjectApiName].required = finalRequiredContextFieldsList;
        }
        Object.keys(schemaProps).map((property:any) => {
            if(Object.keys(schemaProps[property]['properties']).length){
                Object.keys(schemaProps[property]['properties']).forEach((item, index) => {
                    let isTable : boolean = false;
                    if(schemaProps[property]['properties'][item].hasOwnProperty("items") && schemaProps[property]['properties'][item]["items"].hasOwnProperty("$ref")){
                        const tableAPIName=schemaProps[property]['properties'][item]["items"]?.$ref?.split('/').pop();
                        isTable = frozenSchema['definitions'][tableAPIName]['properties'][tableAPIName]?.format === 'table';
                    }
                    if((!isTable) && draggedItems.indexOf(item) === -1 && !item.includes('__r')){
                        delete schemaProps[property]['properties'][item];
                    }
                })
            }
        })

        // remove undragged fields from schema definitions for context object
        if(contextObjectApiName) {
            Object.keys(schema.definitions[contextObjectApiName]['properties']).map((property => {
                if(!draggedItems.includes(contextObjectRelationshipName+'.'+property)) {
                    delete frozenSchema.definitions[contextObjectApiName]['properties'][property];
                }
            }))
        }

       if(Object.keys(frozenSchema.properties.General).length > 0) {
            sectionsInfo.map((numberOfGrid, sectionIndex) => {
                numberOfGrid.map(grid => {
                    if(frozenSchema.definitions[grid.tableId].properties[grid.tableId].hasOwnProperty('format') && frozenSchema.definitions[grid.tableId].properties[grid.tableId]['format'] === 'table') {
                        delete frozenSchema.definitions[grid.tableId].properties[grid.tableId]['format'];
                        if(uiSchema.uischema.type === 'VerticalLayout'){
                            if(uiSchema.uischema.elements[0].type === 'VeriticalLayout' && uiSchema.uischema.elements[0].hasOwnProperty('sectionId')){
                                uiSchema.uischema.elements[sectionIndex].elements[0] = processTableUISchema(uiSchema.uischema.elements[sectionIndex].elements[0], sectionIndex, grid);
                            }else{
                                uiSchema.uischema.elements[sectionIndex] = processTableUISchema(uiSchema.uischema.elements[sectionIndex], sectionIndex, grid);
                            }
                        }else{
                            uiSchema.uischema = processTableUISchema(uiSchema.uischema, sectionIndex, grid);
                        }
                    }
                })
            })
       }
        frozenSchema.properties = schemaProps;
        let formDef : any = {};
        formDef['cqFormType'] = selectedObjectApiName ? selectedObjectApiName : GENERAL;
        formDef['contextObject'] = contextObjectApiName ? contextObjectApiName : '';
        formDef['contextObjectRelationshipName'] = contextObjectRelationshipName ? contextObjectRelationshipName : '';
        formDef['cqFormObjectSchema'] = selectedObjectApiName ? sObjectSchema : null ;
        formDef['schema'] = frozenSchema;
        formDef['ui'] = uiSchema? mergeUISchema(uiSchema.uischema): {};
        formDef['styles'] = formStyleObj;
        formDef['data'] = {};
        formDef['signatures'] = schemaSignatures;
        formDef['sectionsInfo'] = sectionsInfo;
        formDef['cqFormObjectSchema']['primaryLabel'] = selectObjectState && Array.isArray(selectObjectState.selection) && selectObjectState.selection.length > 0 ? selectObjectState.selection[0].label : currentFormData['cqFormObjectSchema']['primaryLabel'];
        if(contextObjectState.selection.length > 0 || (currentFormData && currentFormData['cqFormObjectSchema']['contextLabel'])){
            formDef['cqFormObjectSchema']['contextLabel'] = contextObjectState && Array.isArray(contextObjectState.selection) && contextObjectState.selection.length > 0 ? contextObjectState.selection[0].label : currentFormData['cqFormObjectSchema']['contextLabel'];
        }
        
        if(schemaSignatures.length){
            formDef['ui'].elements.push(signatureUISchema);
        }
        let isFileAttachmentExist = formDef['ui']['elements'].filter((element) => element.label === 'Files');
        let lastElementIndex = formDef['ui']['elements'].length - 1;
        if(isFileAttachmentAdded && !(isFileAttachmentExist.length > 0)) {
            let fileUISchema = {
                "type": "HorizontalLayout",
                "label": "Files",
                "elements": [
                    {
                        "type": "genericfileupload",
                        "scope": `#/properties/${formDef['cqFormType']}/properties/Files`
                    }
                ]
            }
            formDef['ui']['elements'].push(fileUISchema);
            formDef['Files'] = [];
        } else if (isFileAttachmentAdded && (isFileAttachmentExist.length > 0)) {
            const elementIndex = formDef['ui']['elements'].indexOf(isFileAttachmentExist[0]);
            if (elementIndex !== lastElementIndex) {
                formDef['ui']['elements'].splice(elementIndex, 1);
                formDef['ui']['elements'][lastElementIndex] = isFileAttachmentExist[0];
            }
            formDef['Files'] = [];
        } else if (!isFileAttachmentAdded) {
            formDef['ui']['elements'] = formDef['ui']['elements'].filter(element => element.label !== 'Files')
            delete formDef['Files'];
        } else {
            formDef['Files'] = [];
        }
        formDef['version'] = cqFormJSONProcessor.deserializedFormJSON ? cqFormJSONProcessor.deserializedFormJSON["version"]: await sfAPI.getFormsVersion();
        formDef['readOnly'] = readOnlyFieldsList;
        // added nextaction property for selected- object
        const selectedObjectNamespace = selectedObjectApiName.split("__")[0];
        const activityField = `${selectedObjectNamespace}__Activity_Code__c`;
        if(isSafetyInspection || selectedObjectApiName === SUPPLIER_INTERACTION_STEP_OBJECT){
            formDef['nextaction'] = {
                [activityField]: "submit"
            };
        }  
        if(isSafetyInspection){
            let updatedQueriesList : any = queries ? queries : [];
            for(let i=0; i<updatedQueriesList.length; i++ ){
                delete updatedQueriesList[i]['key'];
                delete updatedQueriesList[i]['type'];
            }
            formDef['queries'] = updatedQueriesList;
            let lookupCache : any = [];
            lookupCache.push(JSON.parse(lookupCacheString));
            formDef['lookupCache'] = lookupCache;
        }else{
            formDef['queries'] = [];
        }
        if((Object.keys(generalData).length) || (data?.General && Object.keys(data.General).length)){
            formDef['formBuilderData'] =  generalData;
        }
        
        try{
            setIsFormSave(true);
            let response = await sfAPI.saveFormData(props.recordId, JSON.stringify(formDef), selectedDocs.join());
            if(response === "Success"){
                setSuccessToastMessage(formBuilderScreenStrings.successMessage);
                setShowSuccessToast(true);
                setTimeout(() => {
                    setShowSuccessToast(false);
                    if(localStorage.getItem('instanceurl')){
                        window.open(localStorage.getItem('instanceurl')+'/'+props.recordId ,"_top"); 
                    }
                }, 2000);
            }else{
                setIsFormSave(false);
                setErrorToastMessage(response);
                setShowErrorToast(true);
                setTimeout(() => {
                    setShowErrorToast(false);
                }, 2000)
            }
        }catch(ex){
            setIsFormSave(false);
            console.error(ex);
        }
        
    }

    const mergeUISchema = (uischema : any) => {
        if(Array.isArray(uischema)){
            let mergedUISchema : any = { "type" : "VerticalLayout", "elements": []};
            mergedUISchema["elements"] = uischema;
            return mergedUISchema;
        }
        return uischema;
    }

    /**
     * This method shows file section on clicking file attachment text
     */
    const handleFileClick = () => {
        if(!isFileAttachmentAdded) {
            setIsFileAttachmentAdded(true);
        }
    }

    /**
     * This method removes file section on clicking delete icon
     */ 
    const removeFileSection = () => {
        setIsFileAttachmentAdded(false);
    }


    const handleGridClick = (uniqueId, index) => {
        let newGrid = {numberOfGrid: numberOfGrid.length+1, tableId: uniqueId};
        numberOfGrid.push(newGrid);
        setNumberOfGrid(numberOfGrid);
        setSectionsInfo( (prevState) =>{
            if(!prevState[index].length){
                prevState[index].push(newGrid);
            }else{
                let oldItem = prevState[index].filter((item) => item.tableId === newGrid.tableId);
                if(!oldItem.length){
                    prevState[index].push(newGrid);
                } 
            }
            return prevState;
        })
    }

    const getNumberofGrids = (index) => {
        if(!sectionsInfo) return [];
        return sectionsInfo[index];
    }

    /**
     * This method updates the position of grid when dragged
     * @param result 
     */
    const handleGridUpdate = (result:any) => {
        if(result.source !== null && result.destination !== null) {
            let currentIndex = result.source.index;
            let destinationIndex = result.destination.index;
            let updatingTableId = result.draggableId.split('-')[0];
            let previousTableId = numberOfGrid[destinationIndex].tableId;
            numberOfGrid[destinationIndex].tableId = updatingTableId;
            numberOfGrid[currentIndex].tableId = previousTableId;
        }
    }

    /**
     * This method handles the value that entering in search on Select Object modal
     * @param value is search value in the input
     */
    const handleSelectObjectChange = (value: string) =>{
        let objState = {...selectObjectState};
        objState.inputValue = value;
        setSelectObjectState(objState);
    }

    /**
     * This method handles the value that entering in search on Select Object modal for context field object
     * @param value is search value in the input
     */
    const handleContextObjectChange = (value: string) =>{
        let objState = {...contextObjectState};
        objState.inputValue = value;
        setContextObjectState(objState);
    }

    /**
     * This method handles the selection of option on Select Object modal
     * @param data is information of selected value 
     */
    const handleSelectObjectSelection = async(data: any, mainObjectList:any) =>{
        let objState = {...selectObjectState};
        objState.inputValue= '';
        objState.selection = data.selection;
        await getContextObject(data.selection[0].type, mainObjectList);
        setSelectObjectState(objState);
    }

    /**
     * This method handles the selection of context field object option on select object modal 
     * @param data 
     */
    const handleContextObjectSelection = async(data: any) =>{
        let objState = {...contextObjectState};
        objState.inputValue= '';
        objState.selection = data.selection;
        setContextObjectRelationshipName(data.selection[0]['relationshipName']);
        setContextObjectState(objState);
    }

    /**
     * This method retrieves all lookup and master detail objects for selected mapped field object
     * @param objectName 
     */

    const getContextObject = async(objectName: string, mainObjects)  => {
        let response = await sfAPI.getSeletectedObjectMetadata(objectName);
        if(response !== undefined) {
            response.fields.map((field, index) => {
                if(field.hasOwnProperty('referenceTo') && field['referenceTo'].length > 0) {
                    field['referenceTo'].map((object) => {
                        if(!contextObjectList.includes(object) && object.match(CQFormBuilderConstants.NAMESPACE_REGEX)) {
                            let contextObject:any = {};
                            contextObject.id = index;
                            const filteredContextObject = mainObjects.filter((mainObject) => mainObject.type === object); // stores the context object information from main object
                            // check if selected context object is on the mainobject or not and contains label property or not
                            if(filteredContextObject.length && filteredContextObject[0].hasOwnProperty('label')) {
                                contextObject.label = filteredContextObject[0].label;
                            } else {
                                contextObject.label = object.match(/SQX_(.*?)__c/)[1].split("_").join(" "); // For Excluded objects in main objects list e.g Audit
                            }
                            contextObject.type = object;
                            contextObject.relationshipName = field['relationshipName'];
                            if (!contextObjectList.some((ctxObj:any) => ctxObj.label === contextObject.label)) {
                                contextObjectList.push(contextObject);
                            }
                        }
                    })
                    setContextObjectList(contextObjectList);
                }
            })
        }
    }
    
    /**
     * This method handles the removal of selected option on Select Object modal
     * @param data is information of selection value 
     */
    const handleSelectObjectRemoveSelection = (data: any) =>{
        let objState = {...selectObjectState};
        objState.inputValue= '';
        objState.selection = data.selection;
        setSelectObjectState(objState);
        setContextObjectState(objState);
        setContextObjectList([]);
    }
    /**
     * This method handles the removal of context field object option on Select Object modal
     * @param data 
     */

    const handleContextObjectRemoveSelection = (data: any) =>{
        let objState = {...contextObjectState};
        objState.inputValue= '';
        objState.selection = data.selection;
        setContextObjectState(objState);
    }

    /**
     * This method navigates to Form Builder view after selecting the object from Modal
     */
    const navigateToFormBuilder = () => {
        // check object selected or not and show Form Builder page.
        if(selectObjectState.selection.length){
            setSelectedObjectApiName(selectObjectState.selection[0].type);
            setUISchema(uiSchema);
            // check if context object is selected or not and get metadata for that object
            if (contextObjectState.selection.length) {
                setContextObjectApiName(contextObjectState.selection[0].type);
                getMetadata(selectObjectState.selection[0].type, contextObjectState.selection[0].type);
            } else {
                getMetadata(selectObjectState.selection[0].type);
            }
            setToggleForSelectObject(false);
            setIsObjectSelected(true);
        }
    }
    
    /**
     * This method is used to navigate to Controlled Document when user close the Modal
     */
    const handleCloseModal = () => {
        window.open(localStorage.getItem('instanceurl')+'/'+props.recordId,"_top");
    }

    const [isSectionToggle, setIsSectionToggle] = useState<Boolean[]>([false]);

  /**
   * This method sets isToggle state after onClick action
   * @param i : Index of section
   */
    const toggleSection = (i) => {
        let tempIndex : Boolean[] = [];
        tempIndex = [...isSectionToggle]
        tempIndex[i] = !isSectionToggle[i]
        setIsSectionToggle(tempIndex)
    }

    const handleCloseGenericModal =() => {
        setToggleForGenericModal(false);
        setIsPrimaryFieldModal(false);
    }


    return (
        <>
            <IconSettings iconPath="/assets/icons">

                {isLoaded && isObjectSelected && reload?
                    <div className="slds-theme_default" style={customContainerStyle}>
                        <div className="slds-grid slds-gutters cq-formbuilder-header">
                            <div className="slds-col slds-size_11-of-12" >
                                <span className="slds-text-heading_medium">
                                    {formBuilderScreenStrings.cqFormBuilder}
                                </span>
                            </div>
                            <div className="slds-size_1-of-12 slds-text-align_center" >
                                <Button label={formBuilderScreenStrings.saveForm} variant="brand" onClick={saveFormData}/>
                            </div>
                        </div>
                        <br />
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <div className="slds-grid slds-gutters" >
                                    <div className="slds-col slds-size_9-of-12 cq-formBuilder-content-left slds-m-top_xx-large" style={builderDragDropAreaStyle}>
                                        <div className="slds-grid slds-gutters slds-p-around_medium slds-border_bottom slds-is-relative">
                                            <div className="slds-size_7-of-12">
                                                <CQFormBuilderLogo  isLogoUploaded={isLogoUpdated} imageData={imgSrc} deleteLogo={deleteLogo}/>
                                            </div>
                                            <div className="slds-size_1-of-12 slds-text-link slds-text-align_right slds-p-horizontal_small" onClick={uploadLogo} style={{"cursor" : "default"}}>
                                                {imgSrc ? formBuilderScreenStrings.editLogo : formBuilderScreenStrings.addLogo}
                                            </div>
                                            <div className="slds-size_1-of-12 slds-text-link slds-text-align_center slds-m-left_small" onClick={() => setToggleForSignature(true)} style={{"cursor" : "default"}}>
                                                {formBuilderScreenStrings.addSignature}
                                            </div>
                                            <div className="slds-size_2-of-12 slds-text-link slds-p-left_x-large" onClick={handleFileClick} style={{"cursor" : "default"}}>
                                                {formBuilderScreenStrings.addFileAttachment}
                                            </div>
                                            <div className="slds-size_1-of-12 slds-text-link slds-p-right_x-small" onClick={handleAddSection} style={{"cursor" : "default"}}>
                                                {formBuilderScreenStrings.addSection}
                                            </div>
                                        </div>
                                        {
                                            sectionsInfo.map((item,index) => {
                                                return(
                                                    <section className="slds-accordion__section cq-accordion slds-is-open">
                                                    <CQSectionHeader title={uiSchema.getUiSchema(index)} toggle={toggleSection} index={index} isToggle={isSectionToggle[index]} isFormBuilder={true} setIsdropAreaUpdated={setIsdropAreaUpdated} headerAccordianDisable={true}/>
                                                    <div className="slds-accordion__content" style={{display: isSectionToggle[index] === true ? 'none' : 'block'}}>
                                                            <div className="formBuilderDragArea" style={{marginTop: '10px'}}>
                                                                <div className="slds-grid slds-wrap">
                                                                {isdropAreaUpdated ? 
                                                                <>
                                                                    <JsonForms
                                                                        schema={schema}
                                                                        uischema={uiSchema.getUiSchema(index)}
                                                                        data={data}
                                                                        renderers={EditorRenderers}
                                                                        cells={Cells}
                                                                        ajv={ajv}
                                                                        config={{
                                                                            "cqconfig": {
                                                                                "lookupCache": lookupCacheData,
                                                                                "startingdata": startingDataCache,
                                                                                "queries": queries,
                                                                                "sectionId": index
                                                                            }
                                                                        }}
                                                                        validationMode="NoValidation"
                                                                    />
                                                                    <div className='MuiGrid-container slds-box slds-m-vertical_xxx-small slds-theme_default'>
                                                                        <Droppable 
                                                                            droppableId={'table-drop-' + index}
                                                                            type="FIELD" 
                                                                            direction="vertical">
                                                                            {(provided, snapshot) => (
                                                                            <div ref={provided.innerRef} {...provided.droppableProps}{...provided.dragHandleProps}{...provided.draggableProps}
                                                                                className="slds-p-around_medium slds-m-horizontal_x-small"
                                                                                style={{
                                                                                    'outline': '1px dashed rgba(43,40,38,.35)',
                                                                                    flex: '1 1 100%',
                                                                                    height: '3rem',
                                                                                    backgroundColor: snapshot.isDraggingOver
                                                                                    ? "gainsboro"
                                                                                    : "inherit",
                                                                                    display:'flex',
                                                                                    alignItems:"center",
                                                                                    justifyContent:"center"
                                                                                }}>
                                                                                    {formBuilderScreenStrings.dropTable}
                                                                        
                                                                            </div>
                                                                            )}     
                                                                        </Droppable>
                                                                    </div>
                                                                </>
                                                                    :
                                                                        <Spinner
                                                                            size="medium"
                                                                            variant="base"
                                                                            assistiveText={{ label: strings.loading }}
                                                                        />}
                    
                                                                <div className='slds-size_12-of-12 slds-m-bottom_x-small' style={numberOfGrid.length > 0 ?{border:'1px solid lightgrey', padding: '0.5rem', backgroundColor: 'white'}:{}}>
                                                                        <Droppable droppableId={'GeneralField-table-drag-'+0} type="FIELD" direction="vertical">
                                                                        {(provided) => (
                                                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                                {
                                                                                    sectionsInfo[index] ?
                                                                                    getNumberofGrids(index).map((grid, index) => (
                                                                                            <Draggable key={grid.tableId + '-table-' + index} draggableId={grid.tableId + '-' + index} index={index} isDragDisabled={disableTableDrag}>
                                                                                            {(provided, snapshot) => (
                                                                                                <div className="slds-p-around_xx-small slds-text-heading_small" ref={provided.innerRef} 
                                                                                                    {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                        <CQFormBuilderGrid  
                                                                                                            initialColumnData={Object.keys(schemaTableColumns).length ? 
                                                                                                                schemaTableColumns[grid.tableId] ?
                                                                                                                schemaTableColumns[grid.tableId]: 
                                                                                                                initialColumnData.current: 
                                                                                                                initialColumnData.current
                                                                                                            }
                                                                                                            data={generalData}
                                                                                                            initialRowData={initialRowData.current} 
                                                                                                            tableId={grid.tableId} 
                                                                                                            selectedObjectApiName={selectedObjectApiName}
                                                                                                            relationalAPIName = {getRelationalAPIName(frozenSchema, grid.tableId, selectedObjectApiName)}
                                                                                                            schema={frozenSchema}
                                                                                                            schemaTableHeader={Object.keys(schemaTableHeader).length ? schemaTableHeader[grid.tableId]?.title: undefined}
                                                                                                            totalTableRows={Object.keys(schemaTotalTableRows).length ? schemaTotalTableRows[grid.tableId]?.totalRows: undefined} 
                                                                                                            columnWidth={Object.keys(schemaTableColumnWidth).length ? schemaTableColumnWidth[grid.tableId]: undefined}
                                                                                                            index={index}
                                                                                                        />
                                                                                                    </div>
                                                                                            )}
                                                                                            </Draggable>
                                                                                        )) 
                                                                                 : null
                                                                                }
                                                                            </div>         
                                                                        )}
                                                                        </Droppable>
                                                                </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    </section>
                                                )
                                            })
                                        }
                                        <div className="slds-grid slds-wrap slds-scrollable_y">
                                            <div className='slds-size_12-of-12 slds-m-bottom_x-small'>
                                                {schemaSignatures.length !== 0 ? schemaSignatures.map((schema, index) => (
                                                        <CQFormBuilderSignatureSection key={index} signature={schema["Purpose"]} handleClick={() => removeSignature(schema)} />
                                                )): ""}
                                            </div>
                                            <div className='slds-size_12-of-12 slds-m-bottom_x-small'>
                                                {isFileAttachmentAdded ?
                                                    <CQFormBuilderFileAttachment handleClick={removeFileSection} />
                                                : ""}
                                            </div>
                                        </div>
                                    </div>
                                <div className="slds-size_3-of-12 cq-formBuilder-content-right">
                                    <div className="slds-grid slds-grid_vertical slds-p-horizontal_small" >
                                        <div className="slds-col slds-p-vertical_small slds-m-top_small">
                                            <IconSettings iconPath="/assets/icons">
                                                <Input
                                                    iconLeft={
                                                        <InputIcon

                                                            name="search"
                                                            category="utility"
                                                        />
                                                    }

                                                    id="fieldSearch"
                                                    onChange={filterFieldsAndDocs}
                                                    placeholder={formBuilderScreenStrings.fieldSearch}
                                                />
                                            </IconSettings>
                                        </div>
                                        <div className="slds-scrollable_y" style={{height: '70vh'}}>
                                            {
                                                    
                                                rightPanelItemsList.map((item: any, index) => (
                                                        <Droppable key={item.name + '-' + index} droppableId={item.type} type={item.droptype} isDropDisabled={true}  >
                                                            {(provided) => (
                                                                <>
                                                                    <div className="slds-col slds-p-vertical_small" key={index}  {...provided.droppableProps}
                                                                        ref={provided.innerRef} >
                                                                        <CQFormBuilderSFSection items={item.items} sectionName={item.name} idx={index} isLoaded={isFieldsLoaded} ></CQFormBuilderSFSection>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Droppable>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DragDropContext>
                        <CQGenericFieldModal
                            openModal={toggleForGenericModal || isPrimaryFieldModal}
                            closeModal={handleCloseGenericModal}
                            isDropdown={isDropdown}
                            isTextfield = {isTextfield}
                            isDateField = {isDateField}
                            isBlank = {isBlank}
                            setIsChildDropdown={() => setIsChildDropdown(true)}
                            title = {genericFieldTitle}
                            fieldLabel={fieldLabel}
                            fieldApiName={isPrimaryFieldModal ?  primaryFieldApiName : fieldAPIName}
                            handleChange = {handleGenericFieldChange}
                            fillApiName={handleApiNameChange}
                            handleSave = { isPrimaryFieldModal ? saveModal : saveModal }
                            defaultValue = {defaultValue}
                            dropdownInput = {dropdownLabel}
                            dropdownValues = {dropdownValues}
                            handleDropDownChange={handleDropDownChange}
                            saveDropDownValues = {addDropDownValues}
                            removeDropDownValue = {removeDropdownValue}
                            validations={validations}
                            isPrimaryFieldModal={isPrimaryFieldModal}
                            handleDefaultValue={handleDefaultValue}
                        />
                        <Modal
                            dismissOnClickOutside={false}
                            isOpen = {toggleForSignature}
                            onRequestClose = {() => setToggleForSignature(false)}
                            title={formBuilderScreenStrings.addSignature}
                            ariaHideApp={false}
                            > 
                            <section className="slds-p-around_medium">
                                <div className="slds-gutters" style={modalSectionStyle}>
                                <p className='slds-text-heading_small slds-m-bottom_x-small'>{formBuilderScreenStrings.signature}</p>
                                <input placeholder={formBuilderScreenStrings.signaturePlaceholder} className='slds-p-around_x-small' style={modalInputStyle} value={signature} onChange={(e) => setSignature(e.target.value)}/>
                                <div className='slds-grid slds-m-top_large' style={modalFooterStyle}>
                                    <button className='slds-button slds-button_neutral' onClick={() => setToggleForSignature(false)}>{formBuilderScreenStrings.cancel}</button>
                                    <button className='slds-button slds-button_brand' onClick={saveSignature}>{formBuilderScreenStrings.save}</button>
                                </div>
                                </div>
                            </section>
                        </Modal>
                    </div>
                    
                    : isObjectSelected ?
                    <Spinner
                        size="medium"
                        variant="base"
                        assistiveText={{ label: strings.loading }}
                    /> :    
                    
                    <CQFormBuilderSelectObjectModal 
                        openModal={toggleForSelectObject}
                        handleCloseModal={handleCloseModal}
                        navigateToFormBuilder={navigateToFormBuilder}
                        selectObjectState={selectObjectState}
                        contextObjectState={contextObjectState}
                        contextObjectList={contextObjectList}
                        handleSelectObjectChange={handleSelectObjectChange}
                        handleSelectObjectSelection={handleSelectObjectSelection}
                        handleSelectObjectRemoveSelection={handleSelectObjectRemoveSelection}
                        handleContextObjectChange={handleContextObjectChange}
                        handleContextObjectSelection={handleContextObjectSelection}
                        handleContextObjectRemoveSelection={handleContextObjectRemoveSelection}
                    />
                }
                
            </IconSettings>
            {showToast ?
                <CQToast 
                    variant="error"
                    heading={errorToastMessage}
                    duration={10000}
                />: ""}
            {showSuccessToast ?
                <CQToast 
                    variant="success"
                    heading={successToastMessage}
                />: null
             }
             {showErrorToast ?
                <CQToast 
                    variant="error"
                    heading={errorToastMessage}
                />: null
             }
            {isFormSave ?
                <Spinner
                    size="medium"
                    variant="base"
                    assistiveText={{ label: strings.loading }}
                /> : ""}
        </>
    );
}

export default CQFormBuilderSF;
